
export function registrar_cliente(req){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'cliente/registrar';
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(req)
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}


export function modificar_cliente(id, request, token){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'cliente/modificar_cliente/' + id;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'POST',
            headers: {
                'Authorization': `token ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(request)
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

export function cambiar_contrasena(id, request, token){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'cliente/cambiar_contrasena/' + id;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'POST',
            headers: {
                'Authorization': `token ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(request)
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

export function confirmar_cod_cliente(req,id){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'cliente/validar_code/'+id;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(req)
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}


export function login_cliente(request){
    const base=process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'cliente/login';

    return new Promise((resolve,reject)=>{
        fetch(ws,{
            method:'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(request)
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson);
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

export function datos_login(token){
    const base=process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'cliente/data_login';

    return new Promise((resolve,reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Authorization': `token ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson);
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

export function logout (token){
    const base=process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'cliente/logout';

    return new Promise((resolve,reject)=>{
        fetch(ws,{
            method:'POST',
            headers: {
                'Authorization': `token ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson);
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

export function correo_restablecer_password (request){
    const base=process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'cliente/enviar_restablecer_password';

    return new Promise((resolve,reject)=>{
        fetch(ws,{
            method:'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(request)
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson);
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

export function restablecer_password (request){
    const base=process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'cliente/restablecer_password';

    return new Promise((resolve,reject)=>{
        fetch(ws,{
            method:'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(request)
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson);
        })
        .catch((error)=>{
            reject(error);
        });
    });
}