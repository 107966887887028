import React from 'react'
import imgMant from '../assets/mantenimiento.png'

const Mantenimiento = () => {
    return (
        <div style={{width: '100%', height: '100vh', margin: 'auto', textAlign: 'center'}}>
            <img src={imgMant} alt="Pagina en mantenimiento" width='100%' height='100%'/>
        </div>
    )
}

export default Mantenimiento
