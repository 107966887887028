import {makeStyles } from '@material-ui/core/styles';

export default makeStyles(()=> ({
    root: {
        width: '100%',
        height:' 100%',
        boxShadow:'0 0 10px #999',
        borderRadius:'15px',
    },
    media : {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    cardActions: {
        display : 'flex',
        justifyContent: 'center',
        margin:'0 10px'
    },
    price:{
        color:'#4C4C4C',
        fontWeight:'bold'
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    Button: {
        width:'100%',
        textDecoration:'none',
    }
}));