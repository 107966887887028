import React, {useState, useEffect} from 'react'
import { Button, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@material-ui/core'

import { isAuthenticated } from '../../../services/auth/authentication';
import { listar_departamentos, listar_provincias, listar_distritos, } from "../../../services/Ubigeo";
const NewDireccion = ({ItemsUbigeoCliente, setDatosDireccion}) => {

	const [IdDepartamento, setIdDepartamento] = useState("");
	const [IdProvincia, setIdProvincia] = useState("");
	const [Ubigeo, setUbigeo] = useState("");
	const [Direccion, setDireccion] = useState("");
	const [Referencia, setReferencia] = useState("");
	const [Predeterminada, setPredeterminada] = useState(false)

	const [ItemsDepartamento, setItemsDepartamento] = useState([]);
	const [ItemsProvincia, setItemsProvincia] = useState([]);
	const [ItemsDistrito, setItemsDistrito] = useState([]);

	const _cliente = JSON.parse(localStorage.getItem("_token_webEcommerce")).cliente;
	const _token = JSON.parse(localStorage.getItem("_token_webEcommerce")).token;

	useEffect(() => {
		if (isAuthenticated) {
			if (ItemsUbigeoCliente.length < 3) {
				listadoDepartamentos();
			}
		}
	}, []);

	const listadoDepartamentos = () => {
		listar_departamentos(_token).then((result) => {
		  if (result.exito === 1) {
			setItemsDepartamento(result.data);
		  } else {
			console.log(result.mensaje);
		  }
		});
	};

	const handleChange = (e) => {
		switch (e.target.name) {
		  case "idDepartamento":
			setIdDepartamento(e.target.value);
			listar_provincias(e.target.value, _token).then((rProvncia) => {
			  if (rProvncia.exito == 1) {
				setItemsProvincia(rProvncia.data);
			  } else {
				console.log(rProvncia.mensaje);
			  }
			});
			break;
		  case "idProvincia":
			setIdProvincia(e.target.value);
			listar_distritos(e.target.value, _token).then((rDistrito) => {
			  if (rDistrito.exito == 1) {
				setItemsDistrito(rDistrito.data);
			  } else {
				console.log(rDistrito.mensaje);
			  }
			});
			break;
		  case "ubigeo":
			setUbigeo(e.target.value);
			setDatosDireccion({ id_cliente:_cliente.id, ubigeo: e.target.value, direccion:Direccion, referencia:Referencia, predeterminada: Predeterminada ? 1 : 0 })
			break;
		  case "direccion":
			setDireccion(e.target.value);
			setDatosDireccion({ id_cliente:_cliente.id, ubigeo: Ubigeo, direccion:e.target.value, referencia:Referencia, predeterminada: Predeterminada ? 1 : 0 })
			break;
		  case "referencia":
			setReferencia(e.target.value);
			setDatosDireccion({ id_cliente:_cliente.id, ubigeo: Ubigeo, direccion:Direccion, referencia:e.target.value, predeterminada: Predeterminada ? 1 : 0 })
			break;
		  case "predeterminada":
			setPredeterminada(e.target.checked)
			setDatosDireccion({ id_cliente:_cliente.id, ubigeo: Ubigeo, direccion:Direccion, referencia:Referencia, predeterminada: e.target.checked ? 1 : 0 })
		  default:
			break;
		}
	  };

    return (
        <div>{ItemsUbigeoCliente.length < 3 && (
            <>
              <Grid
                container
                spacing={1}
                style={{
                  border: "1px solid #CCC",
                  padding: "5px",
                  borderRadius: "10px",
                }}
              >
                <Grid item xs={12} spacing={3}>
                  <Typography variant="h6">
                    #{ItemsUbigeoCliente.length + 1}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="idDepartamento">Departamento</InputLabel>
                    <Select
                      labelId="idDepartamento"
                      id="idDepartamento"
                      name="idDepartamento"
                      label="Departamento"
                      onChange={handleChange}
                      value={IdDepartamento}
                    >
                      {ItemsDepartamento.map((item, key) => (
                        <MenuItem key={key} value={item.ubigeo}>
                          {item.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="idProvincia">Provincia</InputLabel>
                    <Select
                      labelId="idProvincia"
                      id="idProvincia"
                      name="idProvincia"
                      label="Provincia"
                      onChange={handleChange}
                      value={IdProvincia}
                    >
                      {ItemsProvincia.map((item, key) => (
                        <MenuItem key={key} value={item.ubigeo}>
                          {item.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="ubigeo">Distrito</InputLabel>
                    <Select
                      labelId="ubigeo"
                      id="ubigeo"
                      name="ubigeo"
                      label="Distrito"
                      onChange={handleChange}
                      value={Ubigeo}
                    >
                      {ItemsDistrito.map((item, key) => (
                        <MenuItem key={key} value={item.ubigeo}>
                          {item.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="direccion"
                    margin="dense"
                    id="direccion"
                    label="Dirección"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={1}
                    onChange={handleChange}
                    value={Direccion}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="referencia"
                    margin="dense"
                    id="referencia"
                    label="Referencia"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={1}
                    onChange={handleChange}
                    value={Referencia}
                  />
                </Grid>
                <Grid item xs={12}>
                <FormControlLabel
                    control={<Switch size="small" color="primary" checked={Predeterminada} name="predeterminada" id="predeterminada" onChange={handleChange} />}
                    label="Dirección predeterminada"
                  />
                </Grid>
              </Grid>
            </>
          )}</div>
    )
}

export default NewDireccion
