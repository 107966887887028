import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Button, Divider, TextField, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom'
import { ToastContext } from "../provider/Toast.provider";
import { SpinnerCircleContext } from "../provider/SpinnerCircle.provider";

import { correo_restablecer_password } from '../services/Client'

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        margin:'auto', 
        maxWidth:'1200px',
      },
      root: {
        flexGrow: 1,
      },
}));

const RecoveryAccount = () => {

    const classes = useStyles();
    const [Email, setEmail] = useState('')
    const history = useHistory();
    const { setData } = useContext(ToastContext);
    const { show, setshow } = useContext(SpinnerCircleContext);

    const handleChange = (e) => {
        switch (e.target.name) {
            case 'email':
                setEmail(e.target.value)
                break;
            default:
                break;
        }
    }

    const recuperarPassword = () => {
        setshow(true)
        if (Email !== '') {
            let request = { email:Email }
            correo_restablecer_password(request).then((result) => {
                console.log(result)
                if (result.exito == 1) {
                    setData({show:true, message:result.mensaje, indicador:1})
                    history.push(`/`);
                } else {
                    setData({show:true, message:result.mensaje, indicador:0})
                }
                setshow(false)
            })
        } else {
            setData({show:true, message:'El campo correo no debe ser vacío...', indicador:0})
            setshow(false)
        }
    }

    return (
        <>
        <div className={classes.toolbar} />
        <div className={classes.content} style={{maxWidth:'60%',}}>
            <Breadcrumbs aria-label="breadcrumb" style={{paddingBottom:'30px'}}>
                <Link to={`/`} style={{textDecorationLine:'none', color:'#333',}}>
                    Home
                </Link>
                <Typography color="textPrimary" style={{textDecorationLine:'none', color:'inherit',textTransform:'capitalize'}}>
                    Recuperar contraseña
                </Typography>
            </Breadcrumbs>
            <Typography variant="h5">Recuperar contraseña</Typography>
            <Typography variant="caption">Ingresa tu correo electrónico y te enviaremos las instrucciones para restaurar tu contraseña.</Typography>
            <br /><br />
            <Divider></Divider>
            <br />
            <TextField id="email" required name="email" type="email" label="Correo electrónico" variant="outlined" style={{width:'60%'}} onFocus onChange={handleChange} value={Email} ></TextField>
            <br /><br />
            <Button id="restablecer" name="restablecer" variant="contained" color="primary" style={{width:'60%'}} onClick={recuperarPassword} > Restablecer contraseña </Button>
            <br /><br />
            <Typography variant="caption">Recuerda revisar tu bandeja de spam.</Typography>
        </div>
        </>
    )
}

export default RecoveryAccount
