import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import NavbarClient from "../components/NavbarClient/NavbarClient";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { listar_ventas_cliente } from "../services/Ventas";
import DetalleVenta from "../components/DetalleVenta/DetalleVenta";
import { Pagination } from "@material-ui/lab";
import { SpinnerCircleContext } from "../provider/SpinnerCircle.provider";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: "60%",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    marginTop: "5px",
    boxShadow: "0 0 10px #999",
  },
}));

const Ordenes = () => {
  const classes = useStyles();

  const [TotalPages, setTotalPages] = useState(1);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let current_page = params.get("page");
  const history = useHistory();
  const [ItemsVentas, setItemsVentas] = useState([]);
  const [MontoEnvio, setMontoEnvio] = useState(0)
  const { show, setshow } = useContext(SpinnerCircleContext);

  const _cliente = JSON.parse(localStorage.getItem("_token_webEcommerce"))
    .cliente;
  const _token = JSON.parse(localStorage.getItem("_token_webEcommerce")).token;

  useEffect(() => {
    listarVentas();
  }, []);

  const listarVentas = () => {
    setshow(true)
    listar_ventas_cliente(_cliente.id, current_page, _token).then((result) => {
      if (result.exito == 1) {
        setItemsVentas(result.data.data);
        setTotalPages(result.data.last_page);
      }
      setshow(false)
    });
  };

  const handleChangePaginate = (e, page) => {
    current_page = page;
    history.push(`/ordenes?page=${page}`);
    listarVentas();
  };

  return (
    <>
      <div className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Mis ordenes</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {ItemsVentas.length > 0
              ? ItemsVentas.map((item, key) => (
                  <Paper key={key} className={classes.paper}>
                    <Grid container spacing={1}>
                      <Grid item xs={10}>
                        <Grid container>
                          <Grid item xs={4}>
                            <Typography variant="subtitle1">
                              {`N° de Orden: ${item.id}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="subtitle1">
                              {`Comprado el: ${item.fecha_emision}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              style={{ fontWeight: "bold" }}
                            >
                              {`Total: S/. ${item.total}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={show}
                          fullWidth
                          href={`/ordenes/detalle/${item.id}`}
                        >
                          Ver pedido
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <DetalleVenta idVenta={item.id} detalle={false} setMontoEnvio={setMontoEnvio} />
                      </Grid>
                    </Grid>
                  </Paper>
                ))
              : null}
            <Box textAlign="center" m={2}>
              <Pagination count={TotalPages} onChange={handleChangePaginate} />
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Ordenes;
