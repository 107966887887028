import React, { createContext, useState } from "react";

export const ToastContext = createContext();

const initialState = {
    show:false,
    message: '',
    indicador: 1
}

export const ToastProvider = ({ children }) => {

    const [data, setData] = useState(initialState)

    return (
        <ToastContext.Provider value={{data, setData}}>
            { children }
        </ToastContext.Provider>
    )
}
