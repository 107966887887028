import React, { useState, useEffect } from 'react';
import { Card, Checkbox, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    root: {
      width: "100%",
      height: "100%",
      boxShadow: "0 0 10px #999",
      borderRadius: "15px",
      padding: "10px",
    },
  }));
const DatosContacto = ({StatuContacto, setStatuContacto, Contacto, setContactoCon}) => {

    const classes = useStyles;
    const [NumeroDocumento, setNumeroDocumento] = useState(Contacto.numero_documento)
    const [Celular, setCelular] = useState(Contacto.celular)
    const [Telefono, setTelefono] = useState(Contacto.telefono)
    const [NombreContacto, setNombreContacto] = useState(Contacto.nombre_contacto)
    const [DisabledContacto, setDisabledContacto] = useState({ numero_documento: true, celular: true, telefono: true, nombre_contacto: true })
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setNumeroDocumento(Contacto.numero_documento)
        setCelular(Contacto.celular)
        setTelefono(Contacto.telefono)
        setNombreContacto(Contacto.nombre_contacto)
        setChecked(StatuContacto)
        if (StatuContacto) {
            setDisabledContacto({numero_documento: false, celular: false, telefono: false, nombre_contacto: false })
        }
    }, [])
    
    const handleChangeChecked = (e) => {
        setChecked(e.target.checked);
        setStatuContacto(e.target.checked)
        setNumeroDocumento('')
        setCelular('')
        setTelefono('')
        setNombreContacto('')
        if (e.target.checked) {
            setDisabledContacto({numero_documento: false, celular: false, telefono: false, nombre_contacto: false })
            setContactoCon({numero_documento: '', celular: '', telefono: '', nombre_contacto: ''})
        } else {
            setDisabledContacto({numero_documento: true, celular: true, telefono: true, nombre_contacto: true })
            setNumeroDocumento(Contacto.numero_documento)
            setCelular(Contacto.celular)
            setTelefono(Contacto.telefono)
            setNombreContacto(Contacto.nombre_contacto)
        }
    };
    
    const handleChangeText = (e) => {
        switch (e.target.name) {
            case 'nro_documento':
                setNumeroDocumento(e.target.value);
                setContactoCon({numero_documento: e.target.value, celular: Celular, telefono: Telefono, nombre_contacto: NombreContacto})
            break;
            case 'celular':
                setCelular(e.target.value);
                setContactoCon({numero_documento: NumeroDocumento, celular: e.target.value, telefono: Telefono, nombre_contacto: NombreContacto})
            break;
            case 'telefono':
                setTelefono(e.target.value);
                setContactoCon({numero_documento: NumeroDocumento, celular: Celular, telefono: e.target.value, nombre_contacto: NombreContacto})
            break;
            case 'nombres':
                setNombreContacto(e.target.value);
                setContactoCon({numero_documento: NumeroDocumento, celular: Celular, telefono: Telefono, nombre_contacto: e.target.value})
            break;
        }
    }

    return (
        <Card className={classes.root} style={{ padding: "10px" }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={1}>
                    <Checkbox
                      color="default"
                      checked={checked}
                      onChange={handleChangeChecked}
                    />
                </Grid>
                <Grid item xs={12} sm={11}>
                    <Typography variant="h5">Datos de Contacto</Typography>
                    <Typography variant="caption">Si otra persona recepcionara el pedido por favor llene los datos del formulario.</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField 
                        name="nro_documento"
                        margin="dense"
                        id="nro_documento"
                        label="*N° Documento"
                        variant="outlined"
                        fullWidth
                        disabled={DisabledContacto.numero_documento}
                        onChange={handleChangeText}
                        value={NumeroDocumento}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField 
                        name="celular"
                        margin="dense"
                        id="celular"
                        label="*Celular"
                        variant="outlined"
                        fullWidth
                        disabled={DisabledContacto.celular}
                        onChange={handleChangeText}
                        value={Celular}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField 
                        name="telefono"
                        margin="dense"
                        id="telefono"
                        label="Teléfono"
                        variant="outlined"
                        fullWidth
                        disabled={DisabledContacto.telefono}
                        onChange={handleChangeText}
                        value={Telefono}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField 
                        name="nombres"
                        margin="dense"
                        id="nombres"
                        label="*Nombres y apellidos"
                        variant="outlined"
                        fullWidth
                        disabled={DisabledContacto.nombre_contacto}
                        onChange={handleChangeText}
                        value={NombreContacto}
                    />
                </Grid>
            </Grid>
        </Card>
    )
}

export default DatosContacto;
