import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import { useParams } from "react-router";
import { ToastContext } from "../provider/Toast.provider";
import { SpinnerCircleContext } from "../provider/SpinnerCircle.provider";

import { restablecer_password } from '../services/Client'

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    content: {
    flexGrow: 1,
        padding: theme.spacing(2),
        margin:'auto', 
        maxWidth:'1200px',
    },
    root: {
        flexGrow: 1,
    },
    showPassword: {
        fontSize: '1rem'
    },
    validaPassTrue: {
        color: '#04AA6D',
        fontSize: '.8rem'
    },
    validaPassFalse: {
        color: '#F00',
        fontSize: '.8rem'
    },
}));

const ResetPassword = () => {

    const classes = useStyles();
    const [Password, setPassword] = useState('')
    const [PasswordConfirmation, setPasswordConfirmation] = useState('')
    const { token, email } = useParams();
    const history = useHistory();
    const { setData } = useContext(ToastContext);
    const { show, setshow } = useContext(SpinnerCircleContext);
    const [ValidaPass, setValidaPass] = useState(false)
    const [ValidaPass2, setValidaPass2] = useState(false)
    const [ItemsValidaPass, setItemsValidaPass] = useState({longitud:false, mayuscula:false, minuscula:false, caracter:false, numero:false})
    const [ShowPassword, setShowPassword] = useState(false)

    const handleChange = (e) => {
        switch (e.target.name) {
            case 'password':
                setPassword(e.target.value.trim())
                ValidarPassword(e.target.value.trim())
                break;
            case 'passwordConfirmation':
                setPasswordConfirmation(e.target.value.trim())
                if (Password===e.target.value.trim()) {
                    setValidaPass2(true)
                } else {
                    setValidaPass2(false)
                }
                break;
            default:
                break;
        }
    }

    const recuperarPassword = () => {
        setshow(true)
        if (Password !== '' && PasswordConfirmation !== '' && ValidaPass && ValidaPass2) {
            let request = { email:email, token:token, password:Password, password_confirmation: PasswordConfirmation }
            restablecer_password(request).then((result) => {
                if (result.exito == 1) {
                    setData({show:true, message:result.mensaje, indicador:1})
                    history.push(`/`);
                } else {
                    if(typeof(result.mensaje) === 'object' && result.mensaje != 'null') {
                        for (const mensaje in result.mensaje) {
                            setData({show:true, message:result.mensaje[mensaje], indicador:0})
                        }
                    } else {
                        setData({show:true, message:result.mensaje, indicador:0})
                    }
                }
                setshow(false)
            })
        } else {
            if (!ValidaPass)
                setData({show:true, message:'La contraseña no cumple los requisitos de seguridad.', indicador:0})
            else if (!ValidaPass2)
                setData({show:true, message:'Las contraseñas no coinciden.', indicador:0})
            else
                setData({show:true, message:'Existen campos vacios para restablecer la contraseña.', indicador:0})
        }
    }

    
    const ValidarPassword = (value) => {
        setValidaPass(false)
        let _longitud = false
        let _mayuscula = false
        let _minuscula = false
        let _caracter = false
        let _numero = false
        if (value.length > 7) {
            _longitud = true
        }
        for(var i = 0;i<value.length;i++) {
                if(value.charCodeAt(i) >= 65 && value.charCodeAt(i) <= 90) {
                    _mayuscula = true;
                } else if(value.charCodeAt(i) >= 97 && value.charCodeAt(i) <= 122) {
                    _minuscula = true;
                } else if(value.charCodeAt(i) >= 48 && value.charCodeAt(i) <= 57) {
                    _numero = true;
                } else {
                    _caracter = true;
                }
            }
        if (_longitud && _mayuscula && _minuscula && _caracter && _numero) {
            setValidaPass(true)
        }
        setItemsValidaPass({longitud: _longitud, mayuscula: _mayuscula, minuscula: _minuscula, caracter:_caracter, numero:_numero})
    }

    const contenerPass = (value) => {
        setShowPassword(value)
    }


    return (
        <div className={classes.content} style={{paddingTop:'80px',margin:'auto', maxWidth:'60%',}}>
            <div className={classes.toolbar} />
            <Typography variant="h5">Restabler contraseña</Typography>
            <Typography variant="caption">Su nueva contraseña debe contener al menos 1 número, 1 caracter especial, 1 letra mayúsculas y 1 minúsculas, la longitud debe de ser mayor a 6 caracteres.</Typography>
            <Divider></Divider>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField error={!ValidaPass} name="password" margin="dense" id="password" label="Nueva contraseña" type="password" variant="outlined" fullWidth onChange={handleChange} value={Password} onFocus={(e)=>contenerPass(true)} onBlur={(e)=>contenerPass(false)}></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField error={!ValidaPass || !ValidaPass2} name="passwordConfirmation" margin="dense" id="passwordConfirmation" label="Confirmar nueva contraseña" type="password" variant="outlined" fullWidth onChange={handleChange} value={PasswordConfirmation}></TextField>
                </Grid>
                { ShowPassword &&
                <Grid item xs={12} sm ={12} className={classes.showPassword}>
                    <Typography variant="caption">La contraseña debe contener:</Typography>
                    <ul style={{margin: '0px'}}>
                        <li className={ItemsValidaPass.longitud ? classes.validaPassTrue : classes.validaPassFalse} >8 caracteres como mínimo.</li>
                        <li className={ItemsValidaPass.mayuscula ? classes.validaPassTrue : classes.validaPassFalse} >Al menos una mayúscula [A-Z].</li>
                        <li className={ItemsValidaPass.minuscula ? classes.validaPassTrue : classes.validaPassFalse} >Al menos una minúscula [a-z].</li>
                        <li className={ItemsValidaPass.numero ? classes.validaPassTrue : classes.validaPassFalse} >Al menos un número [0-9].</li>
                        <li className={ItemsValidaPass.caracter ? classes.validaPassTrue : classes.validaPassFalse} >Al menos un caracter especial [@#$%&...].</li>
                    </ul>
                </Grid>
                }
                <Grid item xs={12} sm={6}>
                    <Button id="restablecer" name="restablecer" variant="contained" disabled={show} color="primary" fullWidth onClick={recuperarPassword} > Cambiar contraseña </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default ResetPassword
