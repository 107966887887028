import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";

import { Navbar, Products } from "./components";
import CarritoCompra from "./components/CarritoCompra/CarritoCompra";
import Categoria from "./components/Categoria/Categoria";
import DetalleProducto from "./components/DetalleProducto/DetalleProducto";
import NavbarProvider from "./provider/Navbar.provider";
import Register from "./pages/Register";
// import Routes from './routes/routes';
import { isAuthenticated } from "./services/auth/authentication";
import { listar_productos_principales } from "./services/Producto";
import RecoveryAccount from "./pages/RecoveryAccount";

import Direcciones from "./pages/Direcciones/Direcciones";

import ResetPassword from "./pages/ResetPassword";


import Ordenes from "./pages/Ordenes";
import CambiarContrasena from "./pages/CambiarContrasena";
import Perfil from "./pages/Perfil";
import CarouselLayout from "./components/Carousel/CarouselLayout";
import DetalleOrden from "./pages/DetalleOrden";
import Toast from "./components/Toast/Toast";
import { ToastProvider } from "./provider/Toast.provider";
import NavbarClient from "./components/NavbarClient/NavbarClient";
import Compra from "./pages/Compra/Compra";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SpinnerCircle from "./components/SpinnerCircle/SpinnerCircle";
import SpinnerCircleProvider from "./provider/SpinnerCircle.provider";

import Footer from "./components/Footer/Footer";

import TerminosCondiciones from "./pages/TerminosCondiciones";
import Mantenimiento from "./pages/Mantenimiento";
import { listarEstadoWeb } from "./services/Empresa";


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


const App = () => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const isAuth = isAuthenticated();
  const [isEstadoWeb, setIsEstadoWeb] = useState(true);
  const fetchProducts = async () => {
    const { data } = await listar_productos_principales(7);
    setProducts(data);
  };



  useEffect(() => {
    verEStadoWeb();
    fetchProducts();
  }, []);

  const verEStadoWeb = async () => {
    const result = await listarEstadoWeb();
    setIsEstadoWeb(result.data.activar_web)
  }

  return (
    <ToastProvider>
      {
        isEstadoWeb == 1 ? <>
        <SpinnerCircleProvider >
          <SpinnerCircle />
        <NavbarProvider>
        <BrowserRouter>
        <Toast />
        <Navbar />
          <div style={{minHeight:'100vh'}}>
            {
              !isAuth ? (
              <Switch>
                <Route exact strict path="/">
                  <CarouselLayout />
                  <Products products={products} />
                </Route>
                <Route exact strict path="/detalle/:id_almacen_stock/:nombre">
                  <DetalleProducto />
                </Route>
                <Route exact strict path="/categoria/:nombre">
                  <Categoria />
                </Route>
                <Route exact strict path="/categoria">
                  <Categoria />
                </Route>
                <Route exact strict path="/register">
                  <Register />
                </Route>
                <Route exact strict path="/carrito">
                  <CarritoCompra />
                </Route>
                <Route exact strict path="/recovery-account">
                  <RecoveryAccount />
                </Route>
                <Route strict path="/recovery-account/reset/:token&&:email">
                  <ResetPassword />
                </Route>
                <Route strict path="/terminos_condiciones">
                  <TerminosCondiciones />
                </Route>
                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            ) : (
              <Switch>
                <Route exact strict path="/">
                  <CarouselLayout />
                  <Products products={products} />
                </Route>
                <Route exact strict path="/detalle/:id_almacen_stock/:nombre">
                  <DetalleProducto />
                </Route>
                <Route exact strict path="/categoria/:nombre">
                  <Categoria />
                </Route>
                <Route exact strict path="/categoria">
                  <Categoria />
                </Route>
                <Route exact strict path="/carrito">
                  <CarritoCompra />
                </Route>
                <Route exact strict path="/perfil">
                  <NavbarClient index={0} />            
                  <Perfil />
                </Route>
                <Route exact strict path="/cambiar_password">
                  <NavbarClient index={1} />
                  <CambiarContrasena />
                </Route>
                <Route exact strict path="/ordenes">
                 <NavbarClient index={2} />
                  <Ordenes />
                </Route>
                <Route exact strict path="/ordenes/detalle/:id_venta">
                  <DetalleOrden />
                </Route>
                <Route exact strict path="/direcciones">
                  <NavbarClient index={3} />
                  <div style={{width:'60%', margin:'auto'}}>
                    <Direcciones />
                  </div>
                </Route>
                <Route exact strict path="/compra">
                  <Compra />
                </Route>
                <Route strict path="/terminos_condiciones">
                  <TerminosCondiciones />
                </Route>
                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            )}
          </div>
        </BrowserRouter>
      </NavbarProvider>
      </SpinnerCircleProvider>
      <Footer />
      </>: <>
        <Route exact strict path="/">
          <Mantenimiento />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </>}
    </ToastProvider>
  );
};

export default App;
