import React, { useEffect, useState, useContext } from 'react'
import { useParams } from "react-router";
import { Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import NavbarClient from '../components/NavbarClient/NavbarClient'
import DetalleVenta from '../components/DetalleVenta/DetalleVenta';
import { listar_venta } from '../services/Ventas'
import { SpinnerCircleContext } from "../provider/SpinnerCircle.provider";

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth: "70%",
    },
    root: {
      flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: "auto",
        marginTop: "5px",
        boxShadow: "0 0 10px #999",
    },
  }));
  

const DetalleOrden = () => {

    const classes = useStyles()
    const _cliente = JSON.parse(localStorage.getItem("_token_webEcommerce")).cliente;
    const _token = JSON.parse(localStorage.getItem("_token_webEcommerce")).token;
    const { id_venta } = useParams();
    const [item, setItem] = useState([])
    const [MontoEnvio, setMontoEnvio] = useState(0)
    const { show, setshow } = useContext(SpinnerCircleContext);

    useEffect(() => {
        listarVenta();
      }, [id_venta]);
    
      const listarVenta = () => {
          setshow(true)
        listar_venta(_cliente.id, id_venta, _token).then((result) => {
          if (result.exito == 1) {
            setItem(result.data[0]);
          }
          setshow(false)
        });
      };

    return (
        <div className={classes.content}>
            <NavbarClient index={2} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">Mis ordenes / Orden N° {id_venta}</Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    { item.serie != undefined &&
                    <Paper>
                        <Grid container spacing={1}>
                            <Grid item xs={10}>
                                <Grid container>
                                {/* <Grid item xs={12} sm={4}>
                                    <Typography variant="subtitle1">
                                    {`N° de comprobante: ${item.serie}-${item.numero}`}
                                    </Typography>
                                </Grid> */}
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="subtitle1">
                                    {`Comprado el: ${item.fecha_emision}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography
                                    variant="subtitle1"
                                    style={{ fontWeight: "bold" }}
                                    >
                                    {`Total: S/. ${item.total}`}
                                    </Typography>
                                </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary" style={{fontWeight:'bold',}}>Resumen de su orden</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Paper className={classes.paper}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <RoomIcon color="primary" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="subtitle1" color="primary" style={{fontWeight:'bold',}}>Dirección de envío</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" color="primary">Tipo de envío: {item.tipo_envio === 'T' ? 'RECOJO EN TIENDA' : 'ENVIO A DOMICILIO'}</Typography>
                                        </Grid>
                                        { item.tipo_envio === 'E' && <>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" color="primary">Departamento: {item.departamento}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" color="primary">Provincia: {item.provincia}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" color="primary">Distrito: {item.distrito}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" color="primary">Dirección: {item.direccion} |  {item.referencia}</Typography>
                                        </Grid>
                                        </>}
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Paper className={classes.paper}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <PersonPinCircleIcon color="primary" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="subtitle1" color="primary" style={{fontWeight:'bold',}}>Datos de contacto</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" color="primary">Nombres: {item.nombre_contacto}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" color="primary">N° documento: {item.numero_documento_contacto}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" color="primary">Celular: {item.celular_contacto}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" color="primary">Teléfono: {item.telefono_contacto}</Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Paper className={classes.paper}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <AttachMoneyIcon color="primary" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="subtitle1" color="primary" style={{fontWeight:'bold',}}>Resumen de pago</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography variant="subtitle1" color="primary">Monto:</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant="subtitle1" style={{fontWeight:'bold', textAlign: 'right'}} color="primary">{`S/. ${parseFloat(item.subtotal) + parseFloat(item.total_igv)}`}</Typography>
                                        </Grid>
                                        {/* <Grid item xs={7}>
                                            <Typography variant="subtitle1" color="primary">IGV({`${item.igv * 100}%`}):</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant="subtitle1" style={{fontWeight:'bold', textAlign: 'right'}} color="primary">{`S/. ${item.total_igv}`}</Typography>
                                        </Grid> */}
                                        <Grid item xs={7}>
                                            <Typography variant="subtitle1" color="primary">(-) Envío:</Typography>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant="subtitle1" style={{fontWeight:'bold', textAlign: 'right'}} color="primary">{`S/. ${MontoEnvio}`}</Typography>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography variant="subtitle1" color="primary">Total:</Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography variant="subtitle1" style={{fontWeight:'bold', textAlign: 'right'}} color="primary">{`S/. ${item.total}`}</Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary" style={{fontWeight:'bold',}}>Detalle de orden</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <DetalleVenta idVenta={item.id} detalle={true} setMontoEnvio={setMontoEnvio} />
                            </Grid>
                        </Grid>
                    </Paper>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default DetalleOrden
