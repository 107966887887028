import React from "react";
import { Grid } from "@material-ui/core";

import Product from "./Product/Product";
import useStyles from "./styles";

const Products = ({ products }) => {
  const classes = useStyles();

  return (
    <>
      <main className={classes.content}>
        <Grid container spacing={4} className={classes.gridContainer}>
          {products.length >= 1
            ? products.map((product) => (
                <Grid
                  item
                  key={product.id_producto}
                  style={{ cursor: "pointer" }}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <Product product={product} />
                </Grid>
              ))
            : null}
        </Grid>
      </main>
    </>
  );
};

export default Products;
