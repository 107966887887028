export function mostrar_costo_envio (ubigeo_origen, ubigeo_destino, token){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'costoenvio/listar_costo_envio_ubigeo/'+ubigeo_origen+'/'+ubigeo_destino;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Authorization': `token ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}