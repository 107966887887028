import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Switch,
  Typography,
} from "@material-ui/core";

import { cambiar_predeterminada } from '../../../services/UbigeoCliente'

const Direccion = ({
  ItemsUbigeoCliente,
  index,
  openDialog,
  handleChangeRadioBtn,
  selectedValueRadioBtn,
  selectDireccion,
  setPredeterminado
}) => {

  const cambiarPredeterminada = (id, e) => {
    if (e.target.checked)  {
      const _token = JSON.parse(localStorage.getItem("_token_webEcommerce")).token;
      cambiar_predeterminada(id, _token).then((result) => {
        if (result.exito==1) {
          setPredeterminado(id)
        } else {
          console.log(result.mensaje)
        }
      })
    }
  }

  return (
    <Grid
      container
      spacing={1}
      style={{
        border: "1px solid #CCC",
        padding: "5px",
        borderRadius: "10px",
      }}
    >
      <Grid item xs={10}>
        { selectDireccion ?
        <FormControlLabel
          control={<Radio color="default" checked={selectedValueRadioBtn === parseInt(ItemsUbigeoCliente.id)} onChange={handleChangeRadioBtn} value={parseInt(ItemsUbigeoCliente.id)} name="radio-buton-direccion" />}
          label={`#${index+1}`}
        />
        :
        <FormControlLabel
          control={<Radio color="default" checked={true} name="radio-buton-direccion" />}
          label={`#${index+1}`}
        />
        }
      </Grid>
      <Grid item xs={2}>
        {selectedValueRadioBtn !== parseInt(ItemsUbigeoCliente.id) ? (
          <IconButton onClick={() => openDialog(ItemsUbigeoCliente.id)}>
            <DeleteIcon color="primary" titleAccess="Eliminar dirección" />
          </IconButton>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="caption" style={{ color: "#666" }}>
          Departamento:
        </Typography>
        <br />
        <Typography variant="subtitle2">
          {ItemsUbigeoCliente.departamento}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="caption" style={{ color: "#666" }}>
          Provincia:
        </Typography>
        <br />
        <Typography variant="subtitle2">
          {ItemsUbigeoCliente.provincia}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="caption" style={{ color: "#666" }}>
          Distrito:
        </Typography>
        <br />
        <Typography variant="subtitle2">
          {ItemsUbigeoCliente.distrito}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" style={{ color: "#666" }}>
          Dirección:
        </Typography>
        <br />
        <Typography variant="subtitle2">
          {ItemsUbigeoCliente.direccion}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" style={{ color: "#666" }}>
          Referencia:
        </Typography>
        <br />
        <Typography variant="subtitle2">
          {ItemsUbigeoCliente.referencia}
        </Typography>
      </Grid>
      <Grid item xs={12}>
          <FormControlLabel
            control={<Switch size="small" color="primary" onChange={(e)=>cambiarPredeterminada(ItemsUbigeoCliente.id, e)} checked={ItemsUbigeoCliente.predeterminada == 1 ? true : false} />}
          /><Typography variant="caption" style={{ color: "#666" }}>Dirección predeterminida</Typography>
      </Grid>
    </Grid>
  );
};

export default Direccion;
