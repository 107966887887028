export function listar_ventas_cliente (id_cliente, current_page){
    const token = JSON.parse(localStorage.getItem('_token_webEcommerce')).token
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'venta/listar_ventas_cliente/' + id_cliente +'?page=' + current_page;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Authorization': `token ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

export function listar_venta (id_cliente, id_venta){
    const token = JSON.parse(localStorage.getItem('_token_webEcommerce')).token
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'venta/listar_venta/' + id_cliente +'/' + id_venta;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Authorization': `token ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

export function listar_detalle_ventas (id_venta){
    const token = JSON.parse(localStorage.getItem('_token_webEcommerce')).token
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'detalle_venta/listar_detalle_venta/' + id_venta;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Authorization': `token ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

export function guardar_venta (request){
    const token = JSON.parse(localStorage.getItem('_token_webEcommerce')).token
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'venta/consumo_culqi';
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'POST',
            headers: {
                'Authorization': `token ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}