export function listar_departamentos (token){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'departamento/listar';
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Authorization': `token ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

export function listar_provincias (ubigeo, token){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'provincia/listar/' + ubigeo;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Authorization': `token ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

export function listar_distritos (ubigeo, token){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'distrito/listar/' + ubigeo;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Authorization': `token ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}