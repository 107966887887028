
import { listar_productos_principales } from '../services/Producto';

export const fetchProducts = async () => {
    const { data } = await listar_productos_principales(7);
    return data;
}

export const handleAddToCart = async (productId = []) => {
    const item = await addCart(productId);
    return item;
}

const addCart = (productId) =>{
    
    let ItemsOnAddToCart = [];
    if(localStorage.getItem('products')){
        ItemsOnAddToCart = JSON.parse(localStorage.getItem('products'));  
        for (let i = 0; i < ItemsOnAddToCart.length; i++) {
            if(productId.id_almacen_stock === ItemsOnAddToCart[i].productId.id_almacen_stock){
                ItemsOnAddToCart[i].productId.cantidad = ItemsOnAddToCart[i].productId.cantidad + productId.cantidad;
                if (ItemsOnAddToCart[i].productId.items.length > 0) {
                    let existe = false
                    for (let j = 0; j < ItemsOnAddToCart[i].productId.items.length; j++) {
                        const element = ItemsOnAddToCart[i].productId.items[j];
                        if (element.id_producto_talla_color == productId.items[0].id_producto_talla_color && element.id_almacen == productId.items[0].id_almacen) {
                            existe = true
                            ItemsOnAddToCart[i].productId.items[j].cantidad = ItemsOnAddToCart[i].productId.items[j].cantidad + productId.cantidad
                        }
                    }
                    if (!existe) {
                        ItemsOnAddToCart[i].productId.items.push(productId.items[0])
                    }
                }
                localStorage.setItem('products', JSON.stringify(ItemsOnAddToCart));
                return JSON.parse(localStorage.getItem('products')).length;
            }
            if(ItemsOnAddToCart.length - 1 === i){
                ItemsOnAddToCart.push({'productId' : productId});
                localStorage.setItem('products', JSON.stringify(ItemsOnAddToCart));
                return JSON.parse(localStorage.getItem('products')).length;
            }  
        }
    }else{
        ItemsOnAddToCart.push({'productId' : productId});
        localStorage.setItem('products', JSON.stringify(ItemsOnAddToCart));
        return JSON.parse(localStorage.getItem('products')).length;
    }
}

export const fetchCart = async () => {
    if(JSON.parse(localStorage.getItem('products')) != null){
        return JSON.parse(localStorage.getItem('products')).length;
    }
    return 0;
}
