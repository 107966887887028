export function listarTallas(idAlmacenStock, idColor){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'producto_talla_color/listar_tallas/'+ idAlmacenStock + '/' + idColor;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

export function listarColores(idAlmacenStock, idTalla){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'producto_talla_color/listar_colores/'+ idAlmacenStock + '/' + idTalla;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

export function listarDetalleSubProducto(idColor, idTalla, idProducto, idAlmacen){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'producto_talla_color/listar_detalle_subproducto/'+ idColor + '/' + idTalla + '/' + idProducto + '/' + idAlmacen;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}