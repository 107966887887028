import React, { useEffect, useState } from "react";
import { Card, Checkbox, FormControlLabel, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { mostrar_un_ubigeo_cliente } from '../../services/UbigeoCliente'
import { Link } from 'react-router-dom'

const DetPedidoProductos = ({Contacto, MetodoEnvio, setTerminos, Terminos}) => {

  const [productos_carrito, setproductos_carrito] = useState([]);
  const [DatosUbigeo, setDatosUbigeo] = useState([])
  const base = process.env.REACT_APP_BASE_URL_DEV;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("products"))) {
      setproductos_carrito(JSON.parse(localStorage.getItem("products")));
    }
    if ( MetodoEnvio.TipoEnvio == 'E' ) {
      verUbigeoCliente()
    }
  }, []);

  const verUbigeoCliente = () => {
    let _token = JSON.parse(localStorage.getItem('_token_webEcommerce')).token
    mostrar_un_ubigeo_cliente(MetodoEnvio.IdDireccion, _token).then((result) => {
      console.log(result)
      if (result.exito==1) {
        setDatosUbigeo(result.data)
      } else {
        console.log(result.mensaje)
      }
    })
  }

  return (
    <Grid container spacing={2} style={{marginTop:'5px'}}>
      <Grid item xs={12} sm={12} style={{backgroundColor:'#FFF', marginBottom: '10px', borderRadius:'5px', boxShadow:'0px 0px 10px #999'}}>
        <Typography variant="subtitle1" style={{fontWeight:'bold', color:'#333'}}>Método de envío</Typography>
        {
          MetodoEnvio.TipoEnvio === 'T' ? 
            <Table stickyHeader aria-label="sticky table">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" style={{color:'#666'}}>Tipo de envío:</Typography><br/>
                    <Typography variant="caption" style={{color:'#333'}}>{ MetodoEnvio.TipoEnvio==='T' ? 'Recojo en tienda' : 'Envío a domiciolio' }</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
          </Table>
          :
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              <TableRow>
                <TableCell style={{paddingTop:'3px', paddingBottom:'5px'}}>
                  <Typography variant="caption" style={{color:'#666'}}>Tipo de envío:</Typography><br/>
                  <Typography variant="caption" style={{color:'#333'}}>{ MetodoEnvio.TipoEnvio==='T' ? 'Recojo en tienda' : 'Envío a domicilio' }</Typography>
                </TableCell>
                <TableCell style={{paddingTop:'3px', paddingBottom:'5px'}}>
                  <Typography variant="caption" style={{color:'#666'}}>Departamento:</Typography><br/>
                  <Typography variant="caption" style={{color:'#333'}}>{ DatosUbigeo.departamento }</Typography>
                </TableCell>
                <TableCell style={{paddingTop:'3px', paddingBottom:'5px'}}>
                  <Typography variant="caption" style={{color:'#666'}}>Provincia:</Typography><br/>
                  <Typography variant="caption" style={{color:'#333'}}>{ DatosUbigeo.provincia }</Typography>
                </TableCell>
                <TableCell style={{paddingTop:'3px', paddingBottom:'5px'}}>
                  <Typography variant="caption" style={{color:'#666'}}>Distrito:</Typography><br/>
                  <Typography variant="caption" style={{color:'#333'}}>{ DatosUbigeo.distrito }</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} style={{paddingTop:'3px', paddingBottom:'5px'}}>
                  <Typography variant="caption" style={{color:'#666'}}>Dirección:</Typography><br/>
                  <Typography variant="caption" style={{color:'#333'}}>{ DatosUbigeo.direccion }</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} style={{paddingTop:'3px', paddingBottom:'5px'}}>
                  <Typography variant="caption" style={{color:'#666'}}>Referencia:</Typography><br/>
                  <Typography variant="caption" style={{color:'#333'}}>{ DatosUbigeo.referencia }</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        }
      </Grid>
      <Grid item xs={12} sm={12} style={{backgroundColor:'#FFF', marginBottom: '10px', borderRadius:'5px', boxShadow:'0px 0px 10px #999'}}>
        <Typography variant="subtitle1" style={{fontWeight:'bold', color:'#333'}}>Datos de contacto</Typography>
        <Table stickyHeader aria-label="sticky table">
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" style={{color:'#666'}}>N° Documento:</Typography><br/>
                <Typography variant="caption" style={{color:'#333'}}>{ Contacto.numero_documento }</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" style={{color:'#666'}}>Contacto:</Typography><br/>
                <Typography variant="caption" style={{color:'#333'}}>{ Contacto.nombre_contacto }</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" style={{color:'#666'}}>Celular:</Typography><br/>
                <Typography variant="caption" style={{color:'#333'}}>{ Contacto.celular }</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" style={{color:'#666'}}>Teléfono:</Typography><br/>
                <Typography variant="caption" style={{color:'#333'}}>{ Contacto.telefono==='' ? '---' : Contacto.telefono }</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} sm={12} style={{backgroundColor:'#FFF', marginBottom: '10px', borderRadius:'5px', boxShadow:'0px 0px 10px #999'}}>
        {productos_carrito.map((i, key) => ( <>
          <Card style={{padding:'0px'}} key={key}>
            <Grid container spacing={2}>
              <Grid item xs={1} sm={1}>
                <img
                  style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
                  alt="complex"
                  src={i.productId.url !== null ? base + `storage/images/products/thumbnails/${i.productId.url}` : base + `storage/images/products/default-image.jpg`}
                />
              </Grid>
              <Grid item xs={11} sm={11} >
                <Grid container direction="column" spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography gutterBottom variant="subtitle1" style={{ color: "#333", fontWeight: "bold" }}>
                      {i.productId.producto}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      Código: {i.productId.codigo}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} >
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                              {i.productId.items.length > 0 && <>
                                <TableCell
                                align='center' style={{backgroundColor:'#CCC', padding:'5px', color:'#666'}}>
                                    Color
                                </TableCell>
                                <TableCell
                                align='center' style={{backgroundColor:'#CCC', padding:'5px', color:'#666'}}>
                                    Talla
                                </TableCell>
                              </>}
                                <TableCell
                                align='center' style={{backgroundColor:'#CCC', padding:'5px', color:'#666'}}>
                                    Precio U.
                                </TableCell>
                                <TableCell
                                align='center' style={{backgroundColor:'#CCC', padding:'5px', color:'#666'}}>
                                    Cantidad
                                </TableCell>
                                <TableCell
                                align='center' style={{backgroundColor:'#CCC', padding:'5px', color:'#666'}}>
                                    Subtotal
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                              {
                                i.productId.items.length > 0 ?
                                  i.productId.items.map((item, key) => ( <>
                                    <TableRow key={key}>
                                      <TableCell
                                      align='center' style={{color:'#333'}}>
                                          <div title={item.color} style={{width:'20px', margin: '5px', height:'20px', backgroundColor: item.hexadecimal, borderRadius: '20px', border: '1px solid #CCC'}}></div>
                                      </TableCell>
                                      <TableCell
                                      align='center' style={{color:'#333'}}>
                                          {item.numero_talla}
                                      </TableCell>
                                      <TableCell
                                      align='center' style={{color:'#333'}}>
                                          S/. {i.productId.precio_publico}
                                      </TableCell>
                                      <TableCell
                                      align='center' style={{fontWeight:'bold',color:'#333'}}>
                                          {item.cantidad}
                                      </TableCell>
                                      <TableCell
                                      align='center' style={{fontWeight:'bold',color:'#333'}}>
                                          S/. {parseFloat(item.cantidad * i.productId.precio_publico).toFixed(2)}
                                      </TableCell>
                                    </TableRow>
                                  </> ))
                                : <>
                                  <TableRow>
                                    <TableCell
                                    align='center' style={{color:'#333'}}>
                                        S/. {i.productId.precio_publico}
                                    </TableCell>
                                    <TableCell
                                    align='center' style={{fontWeight:'bold',color:'#333'}}>
                                        {i.productId.cantidad}
                                    </TableCell>
                                    <TableCell
                                    align='center' style={{fontWeight:'bold',color:'#333'}}>
                                        S/. {parseFloat(i.productId.cantidad * i.productId.precio_publico).toFixed(2)}
                                    </TableCell>
                                  </TableRow>
                                </>
                              }
                        </TableBody>
                    </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Card>
        </>))}
        </Grid>
        <FormControlLabel
          control={<Checkbox checked={Terminos}
          id="terminos"
          name="terminos"
          color="primary"
          onChange={(e)=>setTerminos(e.target.checked)}
          />}
          label={<> Acepto los <Link target="blank" to={`/terminos_condiciones`} style={{color:'#333',}}>Términos y condiciones</Link> de E-commerce. </>}
          labelPlacement="end"
        />
    </Grid>
  );
};

export default DetPedidoProductos;
