import React, {useEffect, useState} from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Link } from "react-router-dom";
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import logCulqi from '../../assets/culqi_logo.png'

const EstadoCompra = ({ datos, culqiToken }) => {

    const [Venta, setVenta] = useState([])
    const [Compra, setCompra] = useState([])
    const [Mensaje, setMensaje] = useState([])
    const [Cliente, setCliente] = useState([])

    useEffect(() => {
        if (datos.data) {
            setVenta(datos.venta.dataVenta)
            setCompra(datos.data)
            setMensaje(datos.data.outcome)
        } else {
            setCompra(datos)
        }

        setCliente(JSON.parse(localStorage.getItem("_token_webEcommerce")).cliente)

    }, [Compra])

    return (
        <div>
            { datos.data ? <>
                <Typography variant="h5" style={{textTransform: 'uppercase'}}><CheckIcon fontSize='large' style={{color:'#4FA734'}} />¡GRACIAS POR TU COMPRA, {Cliente.nombres} {Cliente.ape_pat}!</Typography>
                <Typography variant="caption">Su número de pedido es el: <span style={{fontWeight: 'bolder'}}>{`${Venta.id}`}</span>, conserve este número para futuras consultas.</Typography><br></br>
            </> : <>
                <Typography variant="h5" style={{textTransform: 'uppercase'}}><ErrorIcon fontSize='large' style={{color:'#B13E36'}} />¡SU COMPRA A SIDO RECHAZADA, {Cliente.nombres} {Cliente.ape_pat}!</Typography>
                <Typography variant="caption">Su número de pedido no pudo ser generado, las observaciones del rechazo se detallan lineas abajo.</Typography><br></br>
            </>}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <img src={logCulqi} height={'75'} />
                    <Typography variant="h6">Información de pago</Typography>
                    { datos.data ? <>
                        <Typography variant="caption">Mensaje: { `${Mensaje.user_message}` }</Typography><br/>
                        </> : <>
                        <Typography variant="caption">Mensaje: { `${Compra.user_message}` }</Typography><br/>
                    </>}
                    <Typography variant="caption">Número de tarjeta: { `${culqiToken.card_number}` }</Typography><br/>
                    <Typography variant="caption">Medio de pago:  { `${culqiToken.iin.card_brand}` }</Typography><br/>
                    { datos.data && <>
                        <Typography variant="caption">Cuotas: { `${Compra.installments}` }</Typography><br/>
                    </>}
                    <Typography variant="caption">Estado de pago: { datos.data ? 'Aprobado' : 'Rechazado'}</Typography><br/>
                    { datos.data && <>
                        <Typography variant="caption">N° de comprobante: { `${Venta.serie}-${Venta.numero}` }</Typography><br/>
                    </>}
                </Grid>
                <Grid item xs={12}>
                    { datos.data && <>
                        <Typography variant="caption">
                            Pronto recibiras en tu casilla de correo  <span style={{fontWeight: 'bolder'}}>{Cliente.email}</span> un e-mail indicando el detalle de tu compra o puedes revisarlo en la sección <Link to={`ordenes/detalle/${Venta.id}`} >mis ordenes</Link>.<br/>
                            No olvides verificar tambien tu bandeja de correo no deseado o spam.<br/>
                        </Typography>
                    </>}
                </Grid>
            </Grid>
        </div>
    )
}

export default EstadoCompra
