import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { useContext } from 'react'
import { SpinnerCircleContext } from "../../provider/SpinnerCircle.provider";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));
const SpinnerCircle = () => {
   
    const classes = useStyles();
    const { show, setshow } = useContext(SpinnerCircleContext);
    console.log(show)
    return (
        <Backdrop open={show} className={classes.backdrop} >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default SpinnerCircle;
