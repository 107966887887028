import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Typography,
  Button,
  TextField,
  Popper,
  Grow,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Paper,
  Menu,
  Tooltip,
} from "@material-ui/core";
import { Link as Linkroute } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import "./Navbar.css";
import MenuIcon from "@material-ui/icons/Menu";
import { ShoppingCart } from "@material-ui/icons";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import SearchIcon from '@material-ui/icons/Search';

import useStyles from "./styles";

import { listado_categorias_menu } from "../../services/Categorias";
import { logout } from "../../services/Client";
import { isAuthenticated } from "../../services/auth/authentication";
import { NavbarContext } from "../../provider/Navbar.provider";
import SignIn from "../SignIn/SignIn";
import { listarEstadoWeb } from "../../services/Empresa";

const Navbar = () => {
  const [OpenDialogSignin, setOpenDialogSignin] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { total } = useContext(NavbarContext);
  const classes = useStyles();

  const [listadoCategorias, setListadoCategorias] = useState([]);
  const positionMenu = "left";

  const [DatosLogin, setDatosLogin] = useState([]);
  //ALMACENA TEXTO DE LA BÚSQUEDA
  const [searchtext, setsearchtext] = useState("");
  const [ImgEmpresa, setImgEmpresa] = useState('')
  const base = process.env.REACT_APP_BASE_URL_DEV

  useEffect(() => {
    mostrarImgWeb()
    listado_categorias_menu().then((rCategorias) => {
      setListadoCategorias(rCategorias.data);
    });

    if (isAuthenticated()) {
      setDatosLogin(
        JSON.parse(localStorage.getItem("_token_webEcommerce")).cliente
      );
    }

  }, []);

  const mostrarImgWeb = () => {
    listarEstadoWeb().then((result) => {
        setImgEmpresa(result.data.logo)
    })
  }

  const handleClickOpenIniciarSesion = () => {
    setOpenDialogSignin(true);
    handleClosePerson();
  };

  const cerrarSesion = () => {
    logout(JSON.parse(localStorage.getItem("_token_webEcommerce")).token).then(
      (result) => {
        if (result.exito === 1) {
          localStorage.removeItem("_token_webEcommerce");
          window.location.reload(false);
        }
      }
    );
  };

  const handleClick = (id_categoria, open_cat) => {
    let categorias = [];
    listadoCategorias.map((item, key) => {
      if (item.id === id_categoria) {
        categorias.push({
          id: item.id,
          nombre: item.nombre,
          descripcion: item.descripcion,
          open: !open_cat,
          subcategorias: item.subcategorias,
        });
      } else {
        categorias.push({
          id: item.id,
          nombre: item.nombre,
          descripcion: item.descripcion,
          open: false,
          subcategorias: item.subcategorias,
        });
      }
      setListadoCategorias(categorias);
      return categorias
    });
  };

  const handleClickSub = (id_subcategoria, open_subcat) => {
    let categorias = [];
    listadoCategorias.map((item, key) => {
      let subcategorias = [];
      item.subcategorias.map((row, i) => {
        if (row.id === id_subcategoria) {
          subcategorias.push({
            id: row.id,
            nombre: row.nombre,
            descripcion: row.descripcion,
            open: !open_subcat,
            detalle_sub_categoria: row.detalle_sub_categoria,
          });
        } else {
          subcategorias.push({
            id: row.id,
            nombre: row.nombre,
            descripcion: row.descripcion,
            open: false,
            detalle_sub_categoria: row.detalle_sub_categoria,
          });
        }
        return subcategorias;
      });
      categorias.push({
        id: item.id,
        nombre: item.nombre,
        descripcion: item.descripcion,
        open: item.open,
        subcategorias: subcategorias,
      });
      setListadoCategorias(categorias);
      return categorias;
    });
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  function handleChangeSearch(name, e) {
    if (e.keyCode !== 13) {
      switch (name) {
        case "search":
          setsearchtext(e.target.value);
          break;
        default:
          break;
      }
    } else {
      Search()
    }
  }

  const Search = () => {
    if (searchtext.trim() !== '') {
      window.location.replace(
        `http://${window.location.host}/categoria?q=${searchtext}`
      );
    }
  };

  const [openLogin, setOpenLogin] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpenLogin((prevOpen) => !prevOpen);
  };

  const handleCloseLogin = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenLogin(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenLogin(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openLogin);
  React.useEffect(() => {
    if (prevOpen.current === true && openLogin === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openLogin;
  }, [openLogin]);

  const handleClickPerson = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePerson = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SignIn open={OpenDialogSignin} setopen={setOpenDialogSignin} />
      <AppBar position="fixed" className={classes.appBar} color="inherit">
        <Toolbar>
          <Typography
            variant="h6"
            className={classes.title}
            color="inherit"
            id="logo_navbar"
          >
            <Linkroute
              to="/"
              style={{ textDecoration: "none", color: "#333333" }}
            >
              { ImgEmpresa !== '' &&
              <img
                src={`${base}storage/images/${ImgEmpresa}`}
                alt=""
                height="50px"
                className={classes.image}
              />
              }
            </Linkroute>
          </Typography>
          <Typography
            variant="h6"
            className={classes.categorias}
            color="inherit"
            onClick={toggleDrawer(positionMenu, true)}
          >
          <Tooltip title="Menu de categorias">
            <MenuIcon  />
          </Tooltip>
          </Typography>
          <div className={classes.grow} />
          <TextField
            margin="dense"
            label="¿Qué estás buscando?"
            variant="outlined"
            color="primary"
            className={classes.InputBaseSearch}
            onKeyUp={(e) => handleChangeSearch("search", e)}
            InputProps={{
              endAdornment: <><Button onClick={Search} className={classes.InputSearchText}>Buscar</Button> <SearchIcon fontSize="10"  className={classes.InputSearchIcon} onClick={Search} /> </>,
            }}
          />
          <div className={classes.grow} />
          <div>
            {isAuthenticated() ? (
              <>
                <Button
                  className={classes.ButtonHeader}
                  ref={anchorRef}
                  startIcon={
                    <PersonOutlineOutlinedIcon
                      fontSize="large"
                      style={{ fontSize: "40" }}
                    />
                  }
                  aria-controls={openLogin ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ fontWeight: "bold" }}
                  >
                    Bienvenid@, <br /> {DatosLogin.nombres}
                  </Typography>
                </Button>
                <Popper
                  open={openLogin}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseLogin}>
                          <MenuList
                            autoFocusItem={openLogin}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem onClick={handleCloseLogin}>
                              <Linkroute
                                style={{
                                  textDecoration: "none",
                                  color: "#333333",
                                }}
                                to="/perfil"
                              >
                                Perfil
                              </Linkroute>
                            </MenuItem>
                            <MenuItem onClick={handleCloseLogin}>
                              <Linkroute
                                style={{
                                  textDecoration: "none",
                                  color: "#333333",
                                }}
                                to="/ordenes"
                              >
                                Mis ordenes
                              </Linkroute>
                            </MenuItem>
                            <MenuItem onClick={cerrarSesion}>Salir</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            ) : (
              <>
                <Button
                  startIcon={<PersonAddRoundedIcon/>}
                  className={classes.ButtonHeader}
                  variant="contained"
                  color="primary"
                  id="btn_registrarse_navbar"
                >
                  <Linkroute
                    style={{ textDecoration: "none", color: "#ffffff" }}
                    to="/register"
                  >
                    Registrarse
                  </Linkroute>
                </Button>
                <Button
                  className={classes.ButtonHeader}
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpenIniciarSesion}
                  id="btn_iniciasesion_navbar"
                >
                  Iniciar sesión
                </Button>
              </>
            )}
            <IconButton color="inherit" component={Linkroute} to="/carrito">
              <Badge badgeContent={total} color="primary">
                <ShoppingCart />
              </Badge>
            </IconButton>
            <IconButton
              color="inherit"
              className={classes.PersonIcon}
              onClick={handleClickPerson}
            >
              <PersonIcon />
            </IconButton>
            {!isAuthenticated() ? (
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClosePerson}
              >
                <MenuItem onClick={handleClosePerson}>
                  <Linkroute
                    style={{ textDecoration: "none", color: "#333333" }}
                    to="/register"
                  >
                    Registrarse
                  </Linkroute>
                </MenuItem>
                <MenuItem onClick={handleClickOpenIniciarSesion}>
                  Iniciar Sesión
                </MenuItem>
              </Menu>
            ) : (
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClosePerson}
              >
                <MenuItem onClick={handleClosePerson}>
                  <Linkroute
                    style={{ textDecoration: "none", color: "#333333" }}
                    to="/perfil"
                  >
                    Perfil
                  </Linkroute>
                </MenuItem>
                <MenuItem onClick={handleClosePerson}>
                  <Linkroute
                    style={{ textDecoration: "none", color: "#333333" }}
                    to="/ordenes"
                  >
                    Mis ordenes
                  </Linkroute>
                </MenuItem>
                <MenuItem onClick={cerrarSesion}>Salir</MenuItem>
              </Menu>
            )}
          </div>
        </Toolbar>
      </AppBar>

      {/* MENU DE CATEGORIAS */}
      <div>
        <React.Fragment key={positionMenu}>
          <Drawer
            anchor={positionMenu}
            open={state[positionMenu]}
            onClose={toggleDrawer(positionMenu, false)}
          >
            <div
              className={clsx(classes.list, {
                [classes.fullList]:
                  positionMenu === "top" || positionMenu === "bottom",
              })}
              role="presentation"
            >
              <List>
                {listadoCategorias.map((item, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      button
                      key={item}
                      onClick={() => handleClick(item.id, item.open)}
                    >
                      <ListItemText primary={item.nombre} />
                      {item.open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={item.open} timeout="auto" unmountOnExit>
                      <List componet="div" disablePadding>
                        {item.subcategorias.map((row, i) => (
                          <React.Fragment key={i}>
                            <ListItem
                              button
                              primary={row}
                              className={classes.nested}
                              onClick={() => handleClickSub(row.id, row.open)}
                            >
                              <ListItemText primary={row.nombre} />
                              {row.open ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse
                              in={row.open}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List componet="div" disablePadding>
                                {row.detalle_sub_categoria.map((row2, j) => (
                                  <ListItem
                                    button
                                    primary={row2}
                                    className={classes.nested2}
                                    key={j}
                                  >
                                    <Linkroute
                                      className={classes.linkcats}
                                      onClick={toggleDrawer(
                                        positionMenu,
                                        false
                                      )}
                                      to={{
                                        pathname: `/categoria/${row2.nombre}-${row2.id}`,
                                        datos: { id: row2.id },
                                      }}
                                    >
                                      {row2.nombre}
                                    </Linkroute>
                                  </ListItem>
                                ))}
                              </List>
                            </Collapse>
                          </React.Fragment>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </div>
          </Drawer>
        </React.Fragment>
      </div>
    </>
  );
};

export default Navbar;
