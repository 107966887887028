import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  gridContainer:{
    paddingLeft:'20px',
    paddingRight: '20px'
  },
  titleSlider: {
    position: 'absolute',
    bottom: '125px',
    backgroundColor: 'rgba(0,0,0,.8)',
    color: 'rgba(255,255,255,1)',
    padding: '10px',
    WebkitLineClamp:'2', 
    fontSize: '1.8rem',
    WebkitBoxOrient:'vertical',
    overflow:'hidden',
    display:'-webkit-box'
  },
  descripcionSlider: {
    position: 'absolute',
    bottom: '75px',
    backgroundColor: 'rgba(0,0,0,.8)',
    color: 'rgba(255,255,255,1)',
    padding: '5px',
    WebkitLineClamp:'2', 
    fontSize: '1rem',
    WebkitBoxOrient:'vertical',
    overflow:'hidden',
    display:'-webkit-box'
  },
}));