import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

import App from './App';
import { BrowserRouter } from 'react-router-dom';

const theme = createMuiTheme({
    palette : {
        primary:{
            main: '#393939'
        }, secondary:{
            main:'#ffffff'
        }
    }
})

ReactDOM.render(
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <App />        
            </BrowserRouter>
        </ThemeProvider>,
    document.getElementById('root')
    );