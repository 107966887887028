import { Box, Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import React, {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom";
import { isAuthenticated } from '../../services/auth/authentication'
import useStyles from "./styles";

const ResumenCompra = ({montoCompra, montoEnvio, btnEstado, OpenSignin}) => {

    const history = useHistory();
    const classes = useStyles();
    const [productos_carrito, setproductos_carrito] = useState([])
    const [MontoCalculado, setMontoCalculado] = useState(0)

    useEffect(() => {
      if (JSON.parse(localStorage.getItem('products'))) {
        setproductos_carrito(JSON.parse(localStorage.getItem('products')))
        if (montoCompra > 0) {
          setMontoCalculado(montoCompra)
        } else {
          CalculateTotalPrice()
        }
      } else {
        if (montoCompra > 0) {
          setMontoCalculado(montoCompra)
        } else {
          CalculateTotalPrice()
        }
      }
    }, [montoCompra])

    const ValidarLogueo = () => {
      if (!isAuthenticated()) {
          OpenSignin(true);
      }else {
        history.push(`/compra`);
      }
    };

    // Calcular precio total de todos los productos
  function CalculateTotalPrice() {
      let products = JSON.parse(localStorage.getItem("products"));
      let pricexproduct = 0;
      if (products) {
        for (let i = 0; i < products.length; i++) {
          pricexproduct =
            products[i].productId.cantidad *
              products[i].productId.precio_publico +
            pricexproduct;
          if (products.length - 1 === i) {
            setMontoCalculado(pricexproduct + montoEnvio);
          }
        }
      } else {
        localStorage.removeItem("products");
        setMontoCalculado(0);
        // OpenSignin(false);
      }
  }

    const formatMiles = (valor) => {
        return parseFloat(valor).toFixed(2).replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1.$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",")
    }

    return (
        <>
            <Paper className={classes.paper}>
              <Typography variant="button" style={{fontWeight:'bold', textAlign:'center'}}>Resumen de mi orden</Typography>
              <Typography color="textSecondary">
                {productos_carrito.length === 1 ? (
                  <div>{productos_carrito.length} producto</div>
                ) : (
                  <div> {productos_carrito.length} productos</div>
                )}
              </Typography>
              <Grid container spacing={1} style={{ margin: "10px 0" }}>
              <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                  >
                    Subtotal:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    align="right"
                    variant="subtitle1"
                  >
                    S/. {formatMiles(MontoCalculado)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                  >
                    Envío:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    align="right"
                    variant="subtitle1"
                  >
                    {montoEnvio===0 ? 'Por calcular' : ( montoEnvio===-1 ? 'Recojo en tienda' : `S/. ${formatMiles(montoEnvio)}`)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold" }}
                  >
                    Total:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    align="right"
                    variant="h6"
                    style={{ fontWeight: "bold" }}
                  >
                    S/. { montoEnvio > 0 ? formatMiles((parseFloat(MontoCalculado) + parseFloat(montoEnvio))) : formatMiles(MontoCalculado)}
                  </Typography>
                </Grid>
              </Grid>
              {btnEstado ? (
                <Box textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={ValidarLogueo}
                  >
                    Comprar
                  </Button>
                </Box>
              ) : null}
            </Paper>
          
        </>
    )
}

export default ResumenCompra
