import React, { createContext, useEffect, useState } from "react";

const NavbarProvider = (props) => {
  const [total, settotal] = useState();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("products"))) {
      settotal(JSON.parse(localStorage.getItem("products")).length);
    }
  }, []);
  
  return (
    <div>
      <NavbarContext.Provider value={{ total, settotal }}>
        {props.children}
      </NavbarContext.Provider>
    </div>
  );
};

export default NavbarProvider;
export const NavbarContext = createContext();
