//OBTENER LISTADO DE CATEGORIAS- SUBCATEGORIAS - DETALLE SUBCATEGORIA
export function listado_categorias_menu (){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'categoria/listado_categorias_menu';
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}
//OBTENER MARCAS DE ACUERDO AL ID DE DETALLE SUBCATEGORIA
export function listado_marcas_categoria(id_det_sub,search_words){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'detallesubcategoria/listado_marcas_categoria/'+id_det_sub+'/'+search_words;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}
//OBTENER PRECIO MÁXIMO DE ACUERDO AL DETALLE SUB CATEGORÍA
export function precio_x_det_sub_categoria(id_tienda,id_det_sub,search_words){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'producto/listar_precio_max_categoria/'+id_tienda+'/'+id_det_sub+'/'+search_words;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}
