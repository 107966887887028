import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const useStyles = makeStyles({
  root: {
    width: 'auto',
    margin: 'auto'
  },
});

const  NavbarClient = ({index}) => {
  const classes = useStyles();
  const [value] = React.useState(index);

  return ( <>
    <div className={classes.toolbar} />
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        switch (newValue) {
          case 0:
            window.location.href = '/perfil'            
            break;
          case 1:
            window.location.href = '/cambiar_password'
            break;
          case 2:
            window.location.href = '/ordenes'
            break;
          case 3:
            window.location.href = '/direcciones'
            break;
          default:
            break;
        }
      }}
      showLabels
      className={classes.root}
      style = {{paddingTop:'80px'}}
    >
      <BottomNavigationAction label="Mi perfil" icon={<AccountCircleIcon />} />
      <BottomNavigationAction label="Cambiar contraseña" icon={<VpnKeyIcon />} />
      <BottomNavigationAction label="Mis ordenes" icon={<ShoppingBasketIcon />} />
      <BottomNavigationAction label="Mis direcciones" icon={<LocationOnIcon />} />
    </BottomNavigation>
  </> );
}
export default NavbarClient