//PARA LISTAR PRODUCTOS PRINCIPALES EN PANTALLA PRINCIPAL
export function listar_productos_principales(id_tienda){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'producto/listar_productos_principales/'+ id_tienda;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}
//PARA LISTAR PRODUCTOS POR FILTROS
export function listar_productos_x_filtros(id_tienda,id_det_sub,search_words,marcas,precio_ini,precio_fin,orden,tipo_buscar_por,current_page){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'producto/listar_productos_categoria/'+ id_tienda+'/'+id_det_sub+'/'+search_words+'/'+marcas+'/'+precio_ini+'/'+precio_fin+'/'+orden+'/'+tipo_buscar_por+'?page='+current_page;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}

//PARA LISTAR PRODUCTO DETALLADO
export function mostrar_detalle_producto(id_almacen_stock){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'producto/mostrar_detalle_producto/'+ id_almacen_stock;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}