import React from 'react'
import { Breadcrumbs, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        margin:'auto', 
        maxWidth:'1200px',
      },
      root: {
        flexGrow: 1,
      },
}));

const TerminosCondiciones = () => {

    const classes = useStyles();

    return ( <>
        <div className={classes.toolbar} />
        <div className={classes.content} style={{maxWidth:'80%',}}>
            <Breadcrumbs aria-label="breadcrumb" style={{paddingBottom:'30px'}}>
                <Link to={`/`} style={{textDecorationLine:'none', color:'#333',}}>
                    Home
                </Link>
                <Typography color="textPrimary" style={{textDecorationLine:'none', color:'inherit',textTransform:'capitalize'}}>
                    Terminos y condiciones
                </Typography>
            </Breadcrumbs>
            <Typography variant="h5">Terminos y condiciones</Typography>
            <Typography variant="caption">En E-commerce.com.pe queremos siempre ofrecerle una experiencia de compra que sea relevante y personalizada. Por favor revisa los Términos y Condiciones Generales y las Políticas de privacidad de LINIO PERÚ S.A.C., ya que son las condiciones de venta que rigen en sus compras en nuestro portal Linio.com.pe. Ellos establecen sus derechos y obligaciones con respecto a sus compras, incluyendo importantes limitaciones y exclusiones. La utilización del sitio y/o sus servicios constituye la aceptación de que estas condiciones se aplican a sus compras, así que asegúrese que las entiende antes de realizar sus pedidos.</Typography>
        </div>
    </> )
}

export default TerminosCondiciones
