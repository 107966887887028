import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  Checkbox,
  Slider,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { FilterList } from "@material-ui/icons";
import { useParams } from "react-router";

import {
  listado_marcas_categoria,
  precio_x_det_sub_categoria,
} from "../../services/Categorias";
import { listar_productos_x_filtros } from "../../services/Producto";

import box_image from "../../assets/box.png";

import useStyles from "./styles";
import Products from "../Products/Products";

const Categoria = () => {
  const classes = useStyles();

  const [state_products, setstate_products] = useState(0);
  //ALMACENA PARAMETROS DE LA URL
  const { nombre } = useParams();
  //ALMACENA LISTADO DE MARCAS
  const [list_marcas, setlist_marcas] = useState([]);
  //ALMACENA EL RANGO DE PRECIO MIN-MAX
  const [ValueSliderPrice, setValueSliderPrice] = useState([]);
  //ALMACENA EL PRECIO MÁXIMO
  const [pricemax, setpricemax] = useState(9000);
  //IDE DE TIENDA -- TEMPORALMENTE ESTA EN 7
  const id_tienda = 7;
  //LISTADO DE PRODUCTOS
  const [lst_products, setlst_products] = useState([]);
  //ALMACENA EL ORDEN SEGÚN EL FILTRO
  const [orden, setorden] = useState("ASC");
  const [buscar_por, setbuscar_por] = useState("");
  //TOTAL DE PÁGINAS
  const [TotalPages, setTotalPages] = useState(1);
  const [tipo_buscar_por, settipo_buscar_por] = useState("PRECIO");
  //ACTUALIZA EL PRECIO MÁXIMO EN EL FILTRO SEGÚN EL LISTADO
  const handleChangePrice = (event, newValue) => {
    setValueSliderPrice(newValue);
  };
  const [slider, setslider] = useState(false);
  //PARA LA PÁGINA DE LA RUTA
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let current_page = params.get("page");
  let search_route = params.get("q");
  const history = useHistory();
  const lst_orden = [
    {
      id: "1",
      nombre: "DE MAYOR A MENOR PRECIO",
      nombre_corto: "DESC",
      tipo_buscar_por: "PRECIO",
    },
    {
      id: "2",
      nombre: "DE MENOR A MAYOR PRECIO",
      nombre_corto: "ASC",
      tipo_buscar_por: "PRECIO",
    },
    {
      id: "3",
      nombre: "MARCA",
      nombre_corto: "ASC",
      tipo_buscar_por: "MARCA",
    },
    {
      id: "4",
      nombre: "LOS MÁS NUEVOS",
      nombre_corto: "DESC",
      tipo_buscar_por: "FECHA",
    },
    {
      id: "5",
      nombre: "RECOMENDADOS",
      nombre_corto: "DESC",
      tipo_buscar_por: "RECOMENDADOS",
    },
  ];

  // PARA LA SECCIÓN DE CHECKBOX
  const handleChangeCheckbox = (event) => {
    for (let i = 0; i < list_marcas.length; i++) {
      if (list_marcas[i].nombre === event.target.name) {
        list_marcas[i].checkbox = event.target.checked;
      }
    }
  };
  //PARA LA SELECCIÓN DEL ORDEN DE FILTRO
  function handleChangeOrden(value) {
 
    switch (value) {
      case "1":
        settipo_buscar_por("PRECIO");
        setorden("DESC");
        setbuscar_por("1");
        break;
      case "2":
        settipo_buscar_por("PRECIO");
        setorden("ASC");
        setbuscar_por("2");
        break;
      case "3":
        settipo_buscar_por("MARCA");
        setorden("ASC");
        setbuscar_por("3");
        break;
      case "4":
        settipo_buscar_por("FECHA");
        setorden("ASC");
        setbuscar_por("4");
        break;
        case "5":
        settipo_buscar_por("RECOMENDADOS");
        setorden("ASC");
        setbuscar_por("5");
        break;
      default:
        break;
    }
  }
  //OBTENER LISTADO DE MARCAS DE FILTRO
  function ObtenerMarcas(id) {
    console.log(id, search_route);
    listado_marcas_categoria(id, search_route).then((result) => {
      if (result.exito === 1) {
        setlist_marcas(
          result.data.map((m) => {
            return {
              id: m.id,
              nombre: m.nombre,
              checkbox: false,
            };
          })
        );
      }
    });
  }
  //OBTENER EL PRECIO MÁXIMO SEGÚN EL DETALLE SUB CATEGORÍA
  function ObtenerPrecioMaximo(id_tienda, id_det_sub) {
    precio_x_det_sub_categoria(id_tienda, id_det_sub, search_route).then(
      (result) => {
        if (result.exito === 1) {
          setValueSliderPrice([0, Math.ceil(result.data[0].precio_maximo)]);
          setpricemax(Math.ceil(result.data[0].precio_maximo));
          ObtenerProductosxFiltro(
            id_tienda,
            id_det_sub,
            0,
            0,
            Math.ceil(result.data[0].precio_maximo),
            orden,
            tipo_buscar_por,
            current_page
          );
        }
      }
    );
  }
  //OBTENER LISTADO DE PRODUCTOS
  function ObtenerProductosxFiltro(
    id_tienda,
    id_det_sub,
    marcas,
    precio_ini,
    precio_fin,
    orden,
    tipo_buscar_por,
    current_page
  ) {
    console.log(tipo_buscar_por)
    setslider(true);
    listar_productos_x_filtros(
      id_tienda,
      id_det_sub,
      search_route,
      marcas,
      precio_ini,
      precio_fin,
      orden,
      tipo_buscar_por,
      current_page
    ).then((result) => {
      console.log(result)
      if (result.exito === 1) {
        if (result.data.data.length >= 1) {
          setTotalPages(result.data.last_page);
          setlst_products(result.data.data);
          setslider(false);
          setstate_products(1);
        } else {
          setstate_products(-1);
          setslider(false);
        }
      }
    });
  }
  //BUSQUEDA DE PRODUCTOS POR FILTRO
  function BuscarProductos(search_button, id_det_subcat) {
    if (search_button === 1) {
      current_page = 1;
    }
    let lst_marcas_confirmadas = "0";
    for (let i = 0; i < list_marcas.length; i++) {
      if (list_marcas[i].checkbox === true) {
        lst_marcas_confirmadas =
          lst_marcas_confirmadas + "," + list_marcas[i].id.toString();
      }
      if (list_marcas.length - 1 === i) {
        ObtenerProductosxFiltro(
          id_tienda,
          id_det_subcat,
          lst_marcas_confirmadas,
          ValueSliderPrice[0],
          ValueSliderPrice[1],
          orden,
          tipo_buscar_por,
          current_page
        );
      }
    }
  }

  const handleChangePaginate = (e, page) => {
    current_page = page;
    if (nombre !== undefined) {
      if (search_route != null) {
        history.push(`/categoria?q=${search_route}&page=${page}`);
        BuscarProductos(0, 0);
      } else {
        history.push(`/categoria/${nombre}?page=${page}`);
        BuscarProductos(0, nombre.split("-", 2)[1]);
      }
    } else {
      history.push(`/categoria?q=${search_route}&page=${page}`);
      BuscarProductos(0, 0);
    }
  };

  useEffect(() => {
    if (nombre !== undefined) {
      if (search_route !== null) {
        ObtenerMarcas(0);
        ObtenerPrecioMaximo(id_tienda, 0);
      } else {
        ObtenerMarcas(nombre.split("-", 2)[1]);
        ObtenerPrecioMaximo(id_tienda, nombre.split("-", 2)[1]);
      }
    } else {
      ObtenerMarcas(0);
      ObtenerPrecioMaximo(id_tienda, 0);
    }

    setlist_marcas([]);
    setValueSliderPrice([]);
    setorden("ASC");
    setslider(true);
  }, [nombre]);

  return (
    <>
      {slider ? <LinearProgress style={{ zIndex: "10" }} /> : null}
      <div className={classes.toolbar} />
      <Grid container spacing={3} style={{ width: "100%", margin: "0px" }}>
        <Grid item xs={12} md={3} style={{ padding: "30px" }}>
          <div className={classes.gridFilter}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <Typography style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Filtros
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <FilterList />
                </Box>
              </Grid>
            </Grid>
            <div style={{ margin: "15px 10px" }}>
              <InputLabel style={{ fontSize: "10px", fontWeight: "bold" }}>
                MARCA
              </InputLabel>
              <div>
                {list_marcas.length !== 0 ? (
                  list_marcas.map((i) => {
                    return (
                      <Grid container space={1}>
                        <Grid item space={1}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={handleChangeCheckbox}
                                name={i.nombre}
                              />
                            }
                            label={i.nombre}
                          />
                        </Grid>
                      </Grid>
                    );
                  })
                ) : (
                  <>
                    <div>--</div>
                  </>
                )}
              </div>
            </div>
            <div style={{ margin: "15px 10px" }}>
              <InputLabel style={{ fontSize: "10px", fontWeight: "bold" }}>
                PRECIO
              </InputLabel>
              <Slider
                value={ValueSliderPrice}
                valueLabelDisplay="auto"
                min={0}
                max={pricemax}
                onChange={handleChangePrice}
              />
            </div>
            <div style={{ margin: "15px 10px" }}>
              <InputLabel style={{ fontSize: "10px", fontWeight: "bold" }}>
                ORDENAR POR
              </InputLabel>
              <FormControl fullWidth margin="dense">
                <Select
                  value={buscar_por}
                  onChange={(e) => handleChangeOrden(e.target.value)}
                >
                  {lst_orden.map((data, key) => {
                    return (
                      <MenuItem key={key} value={data.id}>
                        {data.nombre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <Box textAlign="center" m={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (nombre !== undefined) {
                    if (search_route != null) {
                      BuscarProductos(1, 0);
                    } else {
                      BuscarProductos(1, nombre.split("-", 2)[1]);
                    }
                  } else {
                    BuscarProductos(1, 0);
                  }
                }}
              >
                BUSCAR
              </Button>
            </Box>
          </div>
        </Grid>
        {state_products === 1 ? (
          <Grid item xs={12} md={9} style={{ marginTop: "3px" }}>
            <main className={classes.content}>
              <Typography variant="h6" style={{ paddingBottom: "20px" }}>
                Resultado de busqueda:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {search_route ? search_route.toUpperCase() : nombre.split("-")[0]}
                </span>
              </Typography>
              <Grid container spacing={4} className={classes.gridContainer}>
                {lst_products.length >= 1 ? (
                  <Products products={lst_products} />
                ) : null}
              </Grid>
            </main>
            <Box textAlign="center" m={2}>
              <Pagination count={TotalPages} onChange={handleChangePaginate} />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} md={9} lg={9} xl={9} style={{ marginTop: "75px" }}>
            <main className={classes.content}>
              <Box textAlign="center">
                <img src={box_image} alt="Commerce.js" height="150px" />
                {state_products === -1 ? (
                  <Typography variant="subtitle1">
                    No se encontraron resultados de la busqueda{" "}
                    {search_route ? search_route.toUpperCase() : nombre.split("-")[0]}.
                  </Typography>
                ) : (
                  <Typography variant="subtitle1">
                    Buscando coincidencias con{" "}
                    {search_route ? search_route.toUpperCase() : nombre.split("-")[0]} ...
                  </Typography>
                )}
              </Box>
            </main>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Categoria;
