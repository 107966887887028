import React, {useState, useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import NavbarClient from '../components/NavbarClient/NavbarClient'
import { Button, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { cambiar_contrasena } from '../services/Client'
import { ToastContext } from "../provider/Toast.provider";
import { SpinnerCircleContext } from "../provider/SpinnerCircle.provider";

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        margin:'auto', 
        maxWidth:'1200px',
    },
    root: {
        flexGrow: 1,
    },
    showPassword: {
        fontSize: '1rem'
    },
    validaPassTrue: {
        color: '#04AA6D',
        fontSize: '.8rem'
    },
    validaPassFalse: {
        color: '#F00',
        fontSize: '.8rem'
    },
}));

const CambiarContrasena = () => {

    const classes = useStyles();
    const [PasswordActual, setPasswordActual] = useState('')
    const [PasswordNuevo, setPasswordNuevo] = useState('')
    const [PasswordConfirmation, setPassowrdConfirmation] = useState('')
    const { setData } = useContext(ToastContext);
    const [ValidaPass, setValidaPass] = useState(false)
    const [ValidaPass2, setValidaPass2] = useState(false)
    const [ItemsValidaPass, setItemsValidaPass] = useState({longitud:false, mayuscula:false, minuscula:false, caracter:false, numero:false})
    const [ShowPassword, setShowPassword] = useState(false)
    const { show, setshow } = useContext(SpinnerCircleContext);

    const actualizarPassword = () => {
        setshow(true)
        if (PasswordActual !== '' && PasswordNuevo !== '' && PasswordConfirmation !== '' && ValidaPass && ValidaPass2) {
            let _cliente = JSON.parse(localStorage.getItem('_token_webEcommerce')).cliente
            let _token = JSON.parse(localStorage.getItem('_token_webEcommerce')).token
            let request = { password_actual: PasswordActual, password: PasswordNuevo, password_confirmation: PasswordConfirmation, email: _cliente.email}
            cambiar_contrasena(_cliente.id, request, _token).then((result) => {
                if (result.exito == 1) {
                    setData({show:true, message:result.mensaje, indicador:1})
                    setPasswordActual('')
                    setPasswordNuevo('')
                    setPassowrdConfirmation('')
                } else {
                    if(typeof(result.mensaje) === 'object' && result.mensaje != 'null') {
                        for (const mensaje in result.mensaje) {
                            setData({show:true, message:result.mensaje[mensaje], indicador:0})
                        }
                    } else {
                        setData({show:true, message:result.mensaje, indicador:0})
                    }
                }
                setshow(false)
            })
        } else {
            if (!ValidaPass) {
                setData({show:true, message:'La contraseña no cumple los requisistos de seguridad', indicador:0})
            } else if (!ValidaPass2) {
                setData({show:true, message:'Contraseñas no coinciden', indicador:0})
            } else {
                setData({show:true, message:'Existen campos vacios, para proceder al cambio de la contraseña', indicador:0})
            }
            setshow(false)
        }
    }

    const handleChange = (e) => {
        switch (e.target.name) {
            case 'passwordActual':
                setPasswordActual(e.target.value.trim())
                break;
            case 'passwordNuevo':
                setPasswordNuevo(e.target.value.trim())
                ValidarPassword(e.target.value.trim())
                break;
            case 'passwordConfirmation':
                setPassowrdConfirmation(e.target.value.trim())
                if (PasswordNuevo === e.target.value.trim()) {
                    setValidaPass2(true)
                }
                 else {
                    setValidaPass2(false)
                 }
                break;
            default:
                break;
        }
    }

    const ValidarPassword = (value) => {
        setValidaPass(false)
        let _longitud = false
        let _mayuscula = false
        let _minuscula = false
        let _caracter = false
        let _numero = false
        if (value.length > 7) {
            _longitud = true
        }
        for(var i = 0;i<value.length;i++) {
                if(value.charCodeAt(i) >= 65 && value.charCodeAt(i) <= 90) {
                    _mayuscula = true;
                } else if(value.charCodeAt(i) >= 97 && value.charCodeAt(i) <= 122) {
                    _minuscula = true;
                } else if(value.charCodeAt(i) >= 48 && value.charCodeAt(i) <= 57) {
                    _numero = true;
                } else {
                    _caracter = true;
                }
            }
        if (_longitud && _mayuscula && _minuscula && _caracter && _numero) {
            setValidaPass(true)
        }
        setItemsValidaPass({longitud: _longitud, mayuscula: _mayuscula, minuscula: _minuscula, caracter:_caracter, numero:_numero})
    }

    const contenerPass = (value) => {
        setShowPassword(value)
      }

    return (<>
        <div className={classes.content} style={{margin:'auto', width:'60%',}}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Cambiar contraseña
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField name="passwordActual" margin="dense" id="passwordActual" label="Contraseña actual" type="password" variant="outlined" fullWidth onChange={handleChange} value={PasswordActual}></TextField>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={6}>
                    <TextField error={!ValidaPass} name="passwordNuevo" margin="dense" id="passwordNuevo" label="Nueva contraseña" type="password" variant="outlined" fullWidth onChange={handleChange} value={PasswordNuevo} onBlur={(e)=>contenerPass(false)} onFocus={(e)=>contenerPass(true)} ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField error={!ValidaPass2 || !ValidaPass} name="passwordConfirmation" margin="dense" id="passwordConfirmation" label="Confirmar nueva contraseña" type="password" variant="outlined" fullWidth onChange={handleChange} value={PasswordConfirmation}></TextField>
                </Grid>
                { ShowPassword &&
                <Grid item xs={12} sm ={12} className={classes.showPassword}>
                    <Typography variant="caption">La contraseña debe contener:</Typography>
                    <ul style={{margin: '0px'}}>
                        <li className={ItemsValidaPass.longitud ? classes.validaPassTrue : classes.validaPassFalse} >8 caracteres como mínimo.</li>
                        <li className={ItemsValidaPass.mayuscula ? classes.validaPassTrue : classes.validaPassFalse} >Al menos una mayúscula [A-Z].</li>
                        <li className={ItemsValidaPass.minuscula ? classes.validaPassTrue : classes.validaPassFalse} >Al menos una minúscula [a-z].</li>
                        <li className={ItemsValidaPass.numero ? classes.validaPassTrue : classes.validaPassFalse} >Al menos un número [0-9].</li>
                        <li className={ItemsValidaPass.caracter ? classes.validaPassTrue : classes.validaPassFalse} >Al menos un caracter especial [@#$%&...].</li>
                    </ul>
                </Grid>
                }
                <Grid item xs={8}></Grid>
                <Grid item xs={12}>
                    <Button id="actualizar" name="actualizar" onClick={actualizarPassword} disabled={show} variant="contained" color="primary" fullWidth >Actualizar contraseña</Button>
                </Grid>
            </Grid>
        </div>
    </>)
}

export default CambiarContrasena
