import jwt_decode from "jwt-decode"

export const isAuthenticated = () => {

    if (JSON.parse(localStorage.getItem('_token_webEcommerce'))) {
        const _token = JSON.parse(localStorage.getItem('_token_webEcommerce')).token
        const _tokenDecode = jwt_decode(_token)
        const _fechaUnix = parseInt((new Date().getTime() / 1000).toFixed(0))
        if (_tokenDecode.exp >= _fechaUnix)
            return true
        else 
            return false
    } else {
        return false
    }
}