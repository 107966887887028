import React, {useEffect, useState, useContext} from 'react'
import { Box, ButtonBase, Divider, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { listar_detalle_ventas } from '../../services/Ventas'
import { SpinnerCircleContext } from "../../provider/SpinnerCircle.provider";

const useStyles = makeStyles((theme) => ({
      image: {
        width: 50,
        height: 'auto',
        boder: '1px solid #333',
      },
      img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
      },
}));

const DetalleVenta = ({idVenta, detalle, setMontoEnvio}) => {

    const classes = useStyles();
    const base = process.env.REACT_APP_BASE_URL_DEV;

    const [ItemsDetalle, setItemsDetalle] = useState([])
    const _token = JSON.parse(localStorage.getItem('_token_webEcommerce')).token
    const { show, setshow } = useContext(SpinnerCircleContext);

    useEffect(() => {
        listarDetalleVentas()
    }, [idVenta])

    useEffect(() => {
        ItemsDetalle.forEach(item => {
            if (item.delivery==1) {
                setMontoEnvio(item.total)
            }
        })
    }, [ItemsDetalle])

    const listarDetalleVentas = () => {
        setshow(true)
        listar_detalle_ventas(idVenta, _token).then((result) => {
            console.log(result)
            if (result.exito === 1) {
                setItemsDetalle(result.data)
            }
            setshow(false) 
        })
    }

    return (
        ItemsDetalle.map((item, key) => (
            item.delivery == 0 && <>
            <Grid container spacing={1}>
                <Grid item xs={1}>
                    <Box alignItems='center' display='flex' style={{height:'100%', padding:'5px'}}>
                        <ButtonBase className={classes.image}>
                            <img className={classes.img} alt="complex" src={item.imagen !== null ? base+`storage/images/products/${item.imagen}` : base+`storage/images/products/default-image.jpg`} />
                        </ButtonBase>
                    </Box>
                </Grid>
                <Grid item xs={ detalle ? 6 : 11 }>
                    <Box alignItems='center' display='flex' style={{height:'100%', padding:'5px'}}>
                        <Typography variant="subtitle2">
                            {item.detalle}
                            { detalle && <>
                                <br />
                                {`${item.cantidad} UND`}
                                <br />
                                {`S/. ${item.precio_unitario}`}
                            </>}
                        </Typography>
                    </Box>
                </Grid> 
                { detalle &&
                <Grid item xs={5}>
                    {
                    item.subproductos &&
                    <Box alignItems='center' display='flex' style={{height:'100%', padding:'5px'}}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{margin: '0px', padding: '3px', textAlign: 'center', backgroundColor: '#ccc', color: '#333'}}>Color</TableCell>
                                        <TableCell style={{margin: '0px', padding: '3px', textAlign: 'center', backgroundColor: '#ccc', color: '#333'}}>Talla</TableCell>
                                        <TableCell style={{margin: '0px', padding: '3px', textAlign: 'center', backgroundColor: '#ccc', color: '#333'}}>Cantidad</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                    item.subproductos.map((item2, key2) => (
                                    <TableRow>
                                        <TableCell style={{margin: '0px', padding: '3px', textAlign: 'center'}}><div title={item2.detalle.split("|")[0]} style={{width:'15px', margin: 'auto', height: '15px', backgroundColor: item2.detalle.split("|")[1], borderRadius: '20px', border: '1px solid #999'}}></div></TableCell>
                                        <TableCell style={{margin: '0px', padding: '3px', textAlign: 'center'}}>{item2.detalle.split("|")[2]}</TableCell>
                                        <TableCell style={{margin: '0px', padding: '3px', textAlign: 'center'}}>{item2.cantidad}</TableCell>
                                    </TableRow>
                                    ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    }
                </Grid> 
                }
            </Grid>
            <Divider />
        </>))
    )
}

export default DetalleVenta
