import React, { useContext, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { SnackbarContent } from "@material-ui/core";
import { ToastContext } from "../../provider/Toast.provider";

const Toast = () => {
  const { data, setData } = useContext(ToastContext);

  const [stateToast] = useState({
    open: true,
    vertical: "top",
    horizontal: "right",
  });

  const { vertical, horizontal } = stateToast;

  const handleClose = () => {
    setData({ ...data, show: false });
  };

  return (
    <div>
      {(data.indicador === 1) ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={data.show}
          onClose={handleClose}
          autoHideDuration={3000}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{
              backgroundColor: "#43a047",
            }}
            message={<span id="client-snackbar">{data.message}</span>}
          />
        </Snackbar>
      ) : (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={data.show}
          onClose={handleClose}
          autoHideDuration={3000}
          key={vertical + horizontal}
        >
          <SnackbarContent
            style={{
              backgroundColor: "#ff9800",
            }}
            message={<span id="client-snackbar">{data.message}</span>}
          />
        </Snackbar>
      )}
    </div>
  );
};

export default Toast;
