import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import DireccionesCompra from "./DireccionesCompra";
import DatosPersonales from "./DatosPersonales";
import DatosContacto from "./DatosContacto";
import DetPedidoProductos from "./DetPedidoProductos";
import logo from "../../assets/logo_pay.png";
import { guardar_venta } from '../../services/Ventas'
import EstadoCompra from "./EstadoCompra";
import ResumenCompra from "../../components/CarritoCompra/ResumenCompra";
import { mostrar_costo_envio } from '../../services/CostoEnvio'
import { mostrar_un_ubigeo_cliente } from '../../services/UbigeoCliente'
import { mostrar_tienda_principal } from '../../services/Tienda'
import { NavbarContext } from "../../provider/Navbar.provider";
import { SpinnerCircleContext } from "../../provider/SpinnerCircle.provider";
import { useHistory } from "react-router-dom";
import { listarEstadoWeb } from "../../services/Empresa";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    width: "100%",
    height: "100%",
    boxShadow: "0 0 10px #999",
    borderRadius: "15px",
    padding: "10px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: "1200px",
  },
}));

const Compra = () => {
  const classes = useStyles();
  const history = useHistory();
  const { settotal } = useContext(NavbarContext);
  const { show, setshow } = useContext(SpinnerCircleContext);
  const [ IndicadorBtnSiguiente_Direcciones, setIndicadorBtnSiguiente_Direcciones, ] = useState(false);
  const [ IndicadorBtnNextContacto, setIndicadorBtnNextContacto, ] = useState(false);
  const [MontoEnvio, setMontoEnvio] = useState(0)
  const [TotalCompra, setTotalCompra] = useState(0)
  const [TipoEnvio, setTipoEnvio] = useState(0)
  const [IdDireccion, setIdDireccion] = useState(0)
  const [DetalleVenta, setDetalleVenta] = useState([])
  const [Contacto, setContacto] = useState({ numero_documento: '', celular: '', telefono: '', nombre_contacto: '' })
  const [StatuContacto, setStatuContacto] = useState(false)
  const [IdProductoEnvio, setIdProductoEnvio] = useState(0)
  const [CostoEnvio, setCostoEnvio] = useState([])
  const [TotalPagado, setTotalPagado] = useState(0)

  const titleWeb = process.env.REACT_APP_TITLE_WEB;
  const esloganWeb = process.env.REACT_APP_ESLOGAN_WEB;
  const pkCulqi = process.env.REACT_APP_PK_CULQI;
  const [Terminos, setTerminos] = useState(false)

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [StepFinish, setStepFinish] = useState(false)
  const [ResponsePago, setResponsePago] = useState([])
  const [ResponseToken, setResponseToken] = useState([])
  const [ImgEmpresa, setImgEmpresa] = useState('logo.png')
  const base = process.env.REACT_APP_BASE_URL_DEV

  function getSteps() {
    return ["Método de envío", "Datos de contacto", "Detalle del pedido", "Estado de compra"];
  }

  useEffect(() => {
    if (StatuContacto) {
      if (Contacto.numero_documento != '' && Contacto.nombre_contacto != ''  && Contacto.celular != '') {
        setIndicadorBtnNextContacto(false)
      } else {
        setIndicadorBtnNextContacto(true)
      }
    } else {
      const datos = JSON.parse(localStorage.getItem('_token_webEcommerce')).cliente
      setContacto({numero_documento: datos.numero_documento_identidad, celular: datos.celular, telefono: datos.telefono, nombre_contacto: `${datos.nombres} ${datos.ape_pat} ${datos.ape_mat}`})
      setIndicadorBtnNextContacto(true)
    }
  }, [StatuContacto])

  useEffect(() => {
      if (Contacto.numero_documento != '' && Contacto.nombre_contacto != ''  && Contacto.celular != '') {
        setIndicadorBtnNextContacto(true)
      } else {
        setIndicadorBtnNextContacto(false)
      }
  }, [Contacto])

  useEffect(() => {
    if (TipoEnvio==='T') {
      setIdProductoEnvio(0)
      setCostoEnvio([])
      setMontoEnvio(-1)
    } else {
      setIdProductoEnvio(0)
      setCostoEnvio([])
      setMontoEnvio(0)
    }
  }, [TipoEnvio])

  useEffect(() => {
    if (IdDireccion > 0) {
      verUbigeoCliente();
    } else {
      setIdProductoEnvio(0)
      setCostoEnvio([])
      setMontoEnvio(0)
    }
  }, [IdDireccion])

  useEffect(() => {
    if (activeStep == 2) {
      listProducts()
      CalculateTotalPrice()
      window.Culqi.publicKey = pkCulqi;
      window.Culqi.settings({
        title: titleWeb,
        currency: 'PEN',
        description: esloganWeb,
        amount: TotalCompra,
      });
      window.Culqi.options({
        lang: 'es',
        installments: false,
        style: {
          logo: `${base}storage/images/${ImgEmpresa}`,
          maincolor: '#090206',
          buttontext: '#ffffff',
          maintext: '#4A4A4A',
          desctext: '#4A4A4A'
        }
      })
    }
  }, [activeStep]);

  useEffect(() => {
    if(localStorage.getItem("products") !== null){
      mostrarImgWeb()
      let products = JSON.parse(localStorage.getItem("products"));
      if(products.length == 0){
        history.push(`/`);
      }
    }else{
      history.push(`/`);
    }
  }, []);

  const mostrarImgWeb = () => {
    listarEstadoWeb().then((result) => {
        setImgEmpresa(result.data.logo)
    })
  }

  function culqibutton (e) {
    window.Culqi.open();
    e.preventDefault();
  }

  window.culqi = function () {
    setshow(true);
    if (window.Culqi.token) {
      let tokenCulqi = window.Culqi.token.id;
      let emailCulqi = window.Culqi.token.email
      setResponseToken(window.Culqi.token)
      let request = { token_id: tokenCulqi, email:emailCulqi, id_tipo_documento:1, id_ubigeo_cliente: IdDireccion, tipo_envio: TipoEnvio, detalle_venta: DetalleVenta , envio_venta: Contacto, costo_envio: CostoEnvio }
      guardar_venta(request).then((result) => {
          if (result.message) {
            let sms = JSON.parse(result.message)
            setResponsePago(JSON.parse(result.message));
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setStepFinish(true)
            setshow(false);
          } else {
            console.log(result)
            localStorage.removeItem("products");
            settotal(0)
            setResponsePago(result);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setStepFinish(true)
            setshow(false);
          }
      })
    } else {
      alert(window.Culqi.error.user_message);
    }
  }

  function CalculateTotalPrice() {
    let products = JSON.parse(localStorage.getItem("products"));
    let pricexproduct = 0;
    if (products.length >= 1) {
      for (let i = 0; i < products.length; i++) {
        pricexproduct =
          products[i].productId.cantidad *
            products[i].productId.precio_publico +
          pricexproduct;
        if (products.length - 1 === i) {
          let suma = MontoEnvio > 0 ? parseFloat(pricexproduct) + parseFloat(MontoEnvio) : parseFloat(pricexproduct)
          setTotalPagado(suma.toFixed(2))
          let nums = suma.toFixed(2).split('.')
          setTotalCompra(nums[0] + "" + nums[1]);
        }
      }
    } else {
      localStorage.removeItem("products");
      setTotalCompra(0);
    }
  }

  const listProducts = () => {
    let products = JSON.parse(localStorage.getItem('products'))
    let detalle = [];
    let key = 0;
    products.forEach(item => {
      key ++;
      detalle.push({id_almacen_stock: item.productId.id_almacen_stock, detalle: item.productId.producto, cantidad: item.productId.cantidad, precio_unitario: item.productId.precio_publico, descuento_detalle: 0, items: item.productId.items});
      if (key == products.length) {
        setDetalleVenta(detalle);
      }
    });
  }

  const verUbigeoCliente = async () => {
    try {
      let _token = JSON.parse(localStorage.getItem('_token_webEcommerce')).token
      const resUbigeo = await mostrar_un_ubigeo_cliente(IdDireccion, _token)
      if (resUbigeo.exito === 1) {
        const resTienda  = await mostrar_tienda_principal();
        const resCostoEnvio = await mostrar_costo_envio(resTienda.data.ubigeo, resUbigeo.data.ubigeo, _token)
        setMontoEnvio(parseFloat(resCostoEnvio.data.precio).toFixed(2))
        setIdProductoEnvio(resTienda.id_envio)
        setCostoEnvio({ubigeo_origen:resTienda.data.ubigeo, ubigeo_destino: resUbigeo.data.ubigeo, id_producto_envio: resTienda.data.id_envio})
      } else {
        console.log(resUbigeo.mensaje)
      }
    } catch (error) {
      console.log(error)
    }
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid item xs={12} sm={8}>
              <DatosPersonales />
              <DireccionesCompra
                setIndicadorBtnSiguiente={setIndicadorBtnSiguiente_Direcciones} setTipoEnvioDir={setTipoEnvio} TipoEnvio={TipoEnvio} setIdDireccionDir={setIdDireccion} IdDireccion={IdDireccion}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ResumenCompra montoCompra={0} montoEnvio={MontoEnvio} btnEstado={false} OpenSignin={false} />
            </Grid>
          </>
        );
      case 1:
        return (
          <>
            <Grid item xs={12} sm={8}>
              <DatosPersonales />
              <DatosContacto StatuContacto={StatuContacto} setStatuContacto={setStatuContacto} Contacto={Contacto} setContactoCon={setContacto} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ResumenCompra montoCompra={0} montoEnvio={MontoEnvio} btnEstado={false} OpenSignin={false} />
            </Grid>
          </>
        );
      case 2:
        return (
          <>
            <Grid item xs={12} sm={8}>
              <DetPedidoProductos Contacto={Contacto} MetodoEnvio={{TipoEnvio, IdDireccion}} setTerminos={setTerminos} Terminos={Terminos} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ResumenCompra montoCompra={0} montoEnvio={MontoEnvio} btnEstado={false} OpenSignin={false} />
            </Grid>
          </>
        );
      default :
        return (<>
          <Grid item xs={12} sm={8}>
            <EstadoCompra datos={ResponsePago} culqiToken={ResponseToken} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ResumenCompra montoCompra={TotalPagado} montoEnvio={MontoEnvio} btnEstado={false} OpenSignin={false} />
          </Grid>
        </>);
    }
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNext = (e) => {
    
    if(activeStep < 2){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if(activeStep===2){
      culqibutton(e)
    }
    if (activeStep===3) {
      setStepFinish(true)
    }

  };
  return ( <>
    <div className={classes.toolbar} />
    <div
      className={classes.content}
      style={{margin: "auto", width: "75%" }}
    >
      
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid container spacing={2}>
          <>
            {getStepContent(activeStep)}
            {
              !StepFinish && <>
              <Grid item xs={6} sm={6}>
                <Button
                  fullWidth
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                  startIcon={<NavigateBeforeOutlinedIcon />}
                >
                  Atrás
                </Button>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={activeStep===0 ? !IndicadorBtnSiguiente_Direcciones : (activeStep===1 ? !IndicadorBtnNextContacto : !Terminos)}
                  endIcon={activeStep===steps.length - 2 ? <AttachMoneySharpIcon /> : <NavigateNextIcon />}
                >
                  {activeStep === steps.length - 2 ? "Pagar" : "Siguiente"}
                </Button>
              </Grid>
              </>
            }
          </>
        {/* )} */}
      </Grid>
    </div>
  </> );
};

export default Compra;
