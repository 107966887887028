import { Box, Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom'
import React, { useEffect, useState, useContext } from 'react'
import { ToastContext } from "../../provider/Toast.provider";
// import useStyles from './styles';
import { datos_login, login_cliente } from '../../services/Client';
const SignIn =({open,setopen})=> {
    
  const { setData } = useContext(ToastContext);
  const [OpenDialog, setOpenDialog] = useState(false);

    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');

    const handleCloseIniciarSesion = () => {
        setOpenDialog(false);
        setopen(false);
    };

    const handleChange = (name,e) =>{
        switch (name) {
            case 'email':
                setEmail(e.target.value)
                break;
            case 'password':
                setPassword(e.target.value)
                if (e.keyCode === 13) {
                    iniciarSesion();
                }
                break;
            default:
                break;
        }
    }

    const iniciarSesion = () =>{
        if (Email.trim() != '' && Password.trim() != '') {
            let request = { email: Email, password: Password, }
            login_cliente(request).then((result) => {
                if (result.exito === 1) {
                    datos_login(result.data.token).then((rData) => {
                        let data = {token: result.data.token, cliente:rData.data.cliente}
                        localStorage.setItem('_token_webEcommerce', JSON.stringify(data));
                        window.location.reload(false);
                    })
                } else {
                    setData({show:true, message:result.mensaje, indicador:0})
                }
            })
        } else {
            setData({show:true, message:'Ingrese las credenciales correctas.', indicador:0})
        }
    }

    useEffect(() => {
       if(open===true){
        setOpenDialog(true);
       }else{
        setOpenDialog(false);
        setopen(false);
       }
    }, [open])

    return (
        <Dialog open={OpenDialog} onClose={handleCloseIniciarSesion}>
            <DialogTitle >
                <Typography variant="h5" align="center">Inicia Sesión</Typography>
            </DialogTitle>
            <DialogContent >
                <TextField autoFocus margin='dense' label='Correo' type='email' variant='outlined' color='primary' fullWidth onChange={(e)=>handleChange('email',e)} />
                <TextField autoFocus margin='dense' label='Contraseña' type='password' variant='outlined' color='primary' fullWidth  onKeyUp={(e)=>handleChange('password',e)} />
                <Typography  component={'div'}>
                <Link to="/recovery-account" onClick={handleCloseIniciarSesion} style={{color:'#333', textDecorationLine:'none',}} >¿Olvidaste tu contraseña?</Link>
                </Typography>
                <Box textAlign='center' m={2}>
                    <Button variant='contained' onClick={iniciarSesion} color='primary'>Iniciar Sesión</Button>
                </Box>
                <Box textAlign='center' m={2}>
                    <Typography align="center">
                        ¿No tienes cuenta? <Link to="/register" onClick={handleCloseIniciarSesion} style={{color:'#333', textDecorationLine:'none'}} >Regístrate</Link>
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default SignIn;
