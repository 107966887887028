import React, {useState, useEffect, useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { datos_login, modificar_cliente } from '../services/Client'
import { listar_tipos_documentos_identidad } from '../services/DocumentIdentity'
import { ToastContext } from "../provider/Toast.provider";
import { SpinnerCircleContext } from "../provider/SpinnerCircle.provider";

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        margin:'auto', 
        maxWidth:'1200px',
      },
      root: {
        flexGrow: 1,
      },
}));

const Perfil = () => {

    const classes = useStyles();
    const [IdCliente, setIdCliente] = useState('')
    const [TipoDocumento, setTipoDocumento] = useState('')
    const [NumeroDocumento, setNumeroDocumento] = useState('')
    const [RazonSocial, setRazonSocial] = useState('')
    const [Nombres, setNombres] = useState('')
    const [ApePat, setApePat] = useState('')
    const [ApeMat, setApeMat] = useState('')
    const [Email, setEmail] = useState('')
    const [Celular, setCelular] = useState('')
    const [Telefono, setTelefono] = useState('')
    const [Sexo, setSexo] = useState('')
    const { setData } = useContext(ToastContext);
    const { show, setshow } = useContext(SpinnerCircleContext);


    useEffect(() => {
       mostrarDatos()
    }, [])

    const mostrarDatos = () => {
        setshow(true)
        let _token = JSON.parse(localStorage.getItem('_token_webEcommerce')).token
        datos_login(_token).then((result) => {
            if (result.exito == 1) {
                let data = result.data.cliente
                setIdCliente(data.id)
                setNombres(data.nombres)
                setApePat(data.ape_pat)
                setApeMat(data.ape_mat)
                setRazonSocial(data.razon_social)
                setCelular(data.celular)
                setTelefono(data.telefono)
                setNumeroDocumento(data.numero_documento_identidad)
                setSexo(data.sexo)
                setEmail(data.email)
                mostrarTipoDocumento(data.id_tipo_documento_identidad)
                setshow(false)
            }
        }) 
    }

    const mostrarTipoDocumento = (id) => {
        listar_tipos_documentos_identidad().then((result)=>{
            result.data.map((item) => {
                if (id == item.value) {
                    setTipoDocumento(item.label)
                }
            })
        });   
    }

    const actualizarDatos = () => {
        setshow(true)
        let _token = JSON.parse(localStorage.getItem('_token_webEcommerce')).token
        let request = { nombres: Nombres, ape_pat: ApePat, ape_mat: ApeMat, celular: Celular, telefono: Telefono, sexo: Sexo, razon_social: RazonSocial, }
        modificar_cliente(IdCliente, request, _token).then((result) => {
            if (result.exito == 1) {
                setData({show:true, message:result.mensaje, indicador:1})
            } else {
                if(typeof(result.mensaje) === 'object' && result.mensaje != 'null') {
                    for (const mensaje in result.mensaje) {
                        setData({show:true, message:result.mensaje[mensaje], indicador:0})
                    }
                } else {
                    setData({show:true, message:result.mensaje, indicador:0})
                }
            }
            setshow(false)
        })
    }

    const handleChange = (e) => {
        switch (e.target.name) {
            case 'tipoDocumento':
                setTipoDocumento(e.target.value)
                break;
            case 'numeroDocumento':
                setNumeroDocumento(e.target.value)
                break;
            case 'razonSocial':
                setRazonSocial(e.target.value)
                break;
            case 'nombres':
                setNombres(e.target.value)
                break;
            case 'apePat':
                setApePat(e.target.value)
                break;
            case 'apeMat':
                setApeMat(e.target.value)
                break;
            case 'email':
                setEmail(e.target.value)
                break;
            case 'celular':
                setCelular(e.target.value)
                break;
            case 'telefono':
                setTelefono(e.target.value)
                break;
            case 'sexo':
                setSexo(e.target.value)
                break;
            default:
                break;
        }
    }

    return (<>
        <div className={classes.content} style={{margin:'auto', width:'60%',}}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Mi perfil
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField name="tipoDocumento" margin="dense" id="tipoDocumento" label="Tipo documento" variant="outlined" disabled fullWidth onChange={handleChange} value={TipoDocumento}></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField name="numeroDocumento" margin="dense" id="numeroDocumento" label="Número documento" variant="outlined" disabled fullWidth onChange={handleChange} value={NumeroDocumento}></TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField name="razonSocial" margin="dense" id="razonSocial" label="Razón social" variant="outlined" fullWidth onChange={handleChange} value={RazonSocial}></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField name="nombres" margin="dense" id="nombres" label="Nombres" variant="outlined" fullWidth onChange={handleChange} value={Nombres}></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField name="apePat" margin="dense" id="apePat" label="Apellido paterno" variant="outlined" fullWidth onChange={handleChange} value={ApePat}></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField name="apeMat" margin="dense" id="apeMat" label="Apellido materno" variant="outlined" fullWidth onChange={handleChange} value={ApeMat}></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField name="email" margin="dense" id="email" label="Correo electrónico" type="email" variant="outlined" disabled fullWidth onChange={handleChange} value={Email}></TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField name="celular" margin="dense" id="celular" label="Celular" variant="outlined" type="phone" fullWidth onChange={handleChange} value={Celular}></TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField name="telefono" margin="dense" id="telefono" label="Teléfono" variant="outlined" type="phone" fullWidth onChange={handleChange} value={Telefono}></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl required variant="outlined" fullWidth margin="dense">
                        <InputLabel id="sexo">Sexo</InputLabel>
                        <Select labelId="sexo" id="sexo" name="sexo" label="sexo" onChange={handleChange} value={Sexo}>
                            <MenuItem value="M">Masculino</MenuItem>
                            <MenuItem value="F">Femenino</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={12}>
                    <Button id="actualizar" name="actualizar" onClick={actualizarDatos} disabled={show} variant="contained" color="primary" fullWidth >Actualizar datos</Button>
                </Grid>
            </Grid>
        </div>
    </>)
}

export default Perfil
