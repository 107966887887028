import React, { useContext, useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core";
import { Link as Linkroute } from "react-router-dom";
import useStyles from "./styles";
import { handleAddToCart } from "../../../common/AddCart";
import { NavbarContext } from "../../../provider/Navbar.provider";
import { ToastContext } from "../../../provider/Toast.provider";

const Product = ({ product }) => {
  // Context Navbar
  const { settotal } = useContext(NavbarContext);
  // Context Toast
  const { setData } = useContext(ToastContext);

  const classes = useStyles();
  const base = process.env.REACT_APP_BASE_URL_DEV;
  const [product_state] = useState({
    codigo: product.codigo,
    descripcion: product.descripcion,
    id_almacen_stock: product.id_almacen_stock,
    id_producto: product.id_producto,
    marca: product.marca,
    precio_publico: product.precio_publico,
    precio_x_mayor: product.precio_x_mayor,
    producto: product.producto,
    stock: product.stock,
    url: product.url,
    cantidad: 1,
    detalle: product.detalle,
    items: []
  });

  function AgregarProducto(product_state, url) {
    if (product_state.detalle > 0) {
      window.location.href = url
    } else {
      handleAddToCart(product_state).then((result) => {
        setData({ show: true, message: "Producto agregado." ,indicador: 1});
        settotal(result);
      });
    }
  }

  return (
    <Card className={classes.root}>
      <Linkroute
        to={{
          pathname: `/detalle/${product_state.id_almacen_stock}/${product_state.producto}`,
        }}
        style={{ textDecorationLine: "none" }}
      >
        <CardMedia
          className={classes.media}
          image={product_state.url !== null ? base + `storage/images/products/small/${product_state.url}` : base + `storage/images/products/default-image.jpg`}
          title={product_state.name}
        />
      </Linkroute>
      <CardContent>
        <Typography
          style={{
            fontSize: "10px",
            fontWeight: "bold",
            color: "#ababab",
            textDecoration: "none",
          }}
          gutterBottom
        >
          {product_state.marca}
        </Typography>
        <Linkroute
          to={{
            pathname: `/detalle/${product_state.id_almacen_stock}/${product_state.producto}`,
          }}
          style={{ textDecorationLine: "none" }}
        >
          <Typography
            size="18"
            style={{
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              display: "-webkit-box",
              textDecoration: "none",
              color: "#333",
              height: '48px'
            }}
            gutterBottom
          >
            {product_state.producto}
          </Typography>
        </Linkroute>
        <Typography variant="h6" gutterBottom className={classes.price}>
          S/. {product_state.precio_publico}
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontSize: "12px",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            display: "-webkit-box",
            textDecoration: "none",
            height: '34px'
          }}
          color="textSecondary"
        >
          {product_state.descripcion}
        </Typography>
      </CardContent>
      <CardActions disableSpacing className={classes.cardActions}>
        <Button
          className={classes.Button}
          variant="contained"
          color="primary"
          onClick={() => AgregarProducto(product_state, `/detalle/${product_state.id_almacen_stock}/${product_state.producto}`)}
        >
          AGREGAR
        </Button>
      </CardActions>
    </Card>
  );
};

export default Product;
