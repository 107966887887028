import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router";
import useStyles from "./styles";
import ImageGallery from "react-image-gallery";
import {
  Grid,
  Typography,
  LinearProgress,
  TextField,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import "react-image-gallery/styles/css/image-gallery.css";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { handleAddToCart as onAddToCart } from "../../common/AddCart";

import { mostrar_detalle_producto } from "../../services/Producto";
import { NavbarContext } from "../../provider/Navbar.provider";
import { ToastContext } from "../../provider/Toast.provider";
import { listarTallas, listarColores, listarDetalleSubProducto } from '../../services/ProductoTallaColor'

const DetalleProducto = () => {
  // Context Navbar
  const { settotal } = useContext(NavbarContext);
  // Context Toast
  const { setData } = useContext(ToastContext);

  const classes = useStyles();
  const { id_almacen_stock } = useParams();
  const [Producto, setProducto] = useState([]);
  const [Imagenes, setImagenes] = useState([]);
  const [Cantidad, setCantidad] = useState(1);
  const [Slider, setSlider] = useState(false);
  const base = process.env.REACT_APP_BASE_URL_DEV;

  const [IdTalla, setIdTalla] = useState(0)
  const [IdColor, setIdColor] = useState(0)
  const [ItemsTallas, setItemsTallas] = useState([])
  const [ItemsColores, setItemsColores] = useState([])
  const [Stock, setStock] = useState(0)
  const [ProductoTallaColor, setProductoTallaColor] = useState({})

  const [product_state, setproduct_state] = useState({});
  const history = useHistory()

  useEffect(() => {
    setSlider(true);
    mostrar_detalle_producto(id_almacen_stock).then((rProducto) => {
      if (rProducto.data.codigo) {
        setproduct_state({
          codigo: rProducto.data.codigo,
          descripcion: rProducto.data.descripcion,
          id_almacen_stock: rProducto.data.id_almacen_stock,
          id_producto: rProducto.data.id_producto,
          marca: rProducto.data.marca,
          precio_publico: rProducto.data.precio_publico,
          precio_x_mayor: rProducto.data.precio_x_mayor,
          producto: rProducto.data.producto,
          stock: rProducto.data.stock,
          url: rProducto.data.imagenes.length > 0 ? rProducto.data.imagenes[0].imagen : null,
          cantidad: parseInt(Cantidad),
          items: []
        });
        setStock(rProducto.data.stock)
        setProducto(rProducto.data);
        let images = [];
        rProducto.data.imagenes.map((item, key) => {
          images.push({
            original: base + `storage/images/products/${item.imagen}`,
            thumbnail: base + `storage/images/products/thumbnails/${item.imagen}`,
          });
          return images;
        });
        if (images.length === 0) {
          images.push({
            original: base + `storage/images/products/default-image.jpg`,
            thumbnail: base + `storage/images/products/default-image.jpg`,
          });
        }
        setImagenes(images);
        setSlider(false);
      } else {
        history.push('/')
      }
    });
  }, [id_almacen_stock]);

  useEffect(() => {
    mostrarColores()
    mostrarTallas()
  }, [id_almacen_stock])

  useEffect(() => {
    mostrarTallas()
  }, [IdColor])

  const mostrarColores = () => {
    listarColores(id_almacen_stock, IdTalla).then((result) => {
      if (result.exito === 1) {
        setItemsColores(result.data)
      }
    })
  }

  const mostrarTallas = () => {
    listarTallas(id_almacen_stock, IdColor).then((result) => {
      if (result.exito === 1) {
        setItemsTallas(result.data)
      }
    })
  }

  const handleChange = (e) => {
    if ( ItemsColores.length > 0 && ItemsTallas.length > 0) {
      if (IdColor === 0 || IdTalla === 0) {
        setData({show: true, message: 'Debe seleccionar un color y talla.', indicador: 0})
        return false
      }
    }
    if (e.target.id === "cantidad") {
      if (parseInt(e.target.value) > 0) {
        if (parseInt(e.target.value) <= Producto.stock) {
          setCantidad(e.target.value);
          setproduct_state({
            codigo: Producto.codigo,
            descripcion: Producto.descripcion,
            id_almacen_stock: Producto.id_almacen_stock,
            id_producto: Producto.id_producto,
            marca: Producto.marca,
            precio_publico: Producto.precio_publico,
            precio_x_mayor: Producto.precio_x_mayor,
            producto: Producto.producto,
            stock: Producto.stock,
            url: Producto.imagenes.length > 0 ? Producto.imagenes[0].imagen : null,
            cantidad: parseInt(e.target.value),
            items: (ItemsColores.length > 0 && ItemsTallas.length > 0) ? [{id: ProductoTallaColor.id, id_producto_talla_color: ProductoTallaColor.id_producto_talla_color, id_almacen: ProductoTallaColor.id_almacen, stock: ProductoTallaColor.stock, id_color: IdColor, id_talla: IdTalla, color: ProductoTallaColor.color, hexadecimal: ProductoTallaColor.hexadecimal, numero_talla: ProductoTallaColor.numero_talla, cantidad: parseInt(e.target.value),}] : {}
          });
        } else {
          setData({show: true, message: `La cantidad ingresada no debe ser mayor al stock actual: ${Producto.stock}`, indicador: 0})
          setCantidad(1);
        }
      } else {
        setCantidad(1);
      }
    }
  };

  function AgregarProducto(product_state) {
    if (ItemsTallas.length > 0 && ItemsColores.length > 0) {
      if (IdTalla > 0 && IdColor > 0) {
        onAddToCart(product_state).then((result) => {
          setData({show:true,message:'Producto agregado.',indicador:1});
          settotal(result);
        });
      } else {
        setData({show: true, message: 'Debe seleccionar una talla y color.', indicador: 0})
      }
    } else {
      onAddToCart(product_state).then((result) => {
        setData({show:true,message:'Producto agregado.',indicador:1});
        settotal(result);
      });
    }
  }

  const validarNumero = (e) => {
    var ch = String.fromCharCode(e.which);
    if (!/[0-9]/.test(ch)) {
      e.preventDefault();
    }
  }

  const handleChangeColor = (idColor) => {
    setIdColor(idColor)
    setIdTalla(0)
  }

  const handleChangeTalla = async (e) => {
    setIdTalla(e.target.value)
    const result = await listarDetalleSubProducto(IdColor, e.target.value, Producto.id_producto, Producto.id_almacen)
    if (result.exito === 1) {
      setproduct_state({
        codigo: Producto.codigo,
        descripcion: Producto.descripcion,
        id_almacen_stock: Producto.id_almacen_stock,
        id_producto: Producto.id_producto,
        marca: Producto.marca,
        precio_publico: Producto.precio_publico,
        precio_x_mayor: Producto.precio_x_mayor,
        producto: Producto.producto,
        stock: Producto.stock,
        url: Producto.imagenes.length > 0 ? Producto.imagenes[0].imagen : null,
        cantidad: parseInt(Cantidad),
        items: (ItemsColores.length > 0 && ItemsTallas.length > 0) ? [{id: result.data.id, id_producto_talla_color: result.data.id_producto_talla_color, id_almacen: result.data.id_almacen, stock: result.data.stock, id_color: IdColor, id_talla: e.target.value, color: result.data.color, hexadecimal: result.data.hexadecimal, numero_talla: result.data.numero_talla, cantidad: parseInt(Cantidad),}] : {}
      });
      setProductoTallaColor(result.data)
      setStock(result.data.stock)
    }
  }

  return (
    <>
      {Slider ? <LinearProgress style={{ zIndex: "10" }} /> : null}
      {Imagenes.length > 0 && (
        <>
          <div style={{ margin: "auto", maxWidth: "1200px", padding: "25px" }}>
            <div
              className={classes.toolbar}
              style={{ paddingBottom: "30px" }}
            />
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{ paddingBottom: "30px" }}
            >
              <Link
                to={`/`}
                style={{ textDecorationLine: "none", color: "#333" }}
              >
                Home
              </Link>
              <Typography
                color="textPrimary"
                style={{
                  textDecorationLine: "none",
                  color: "inherit",
                  textTransform: "capitalize",
                }}
              >
                {Producto.categoria.toLowerCase()}
              </Typography>
              <Typography
                color="textPrimary"
                style={{
                  textDecorationLine: "none",
                  color: "inherit",
                  textTransform: "capitalize",
                }}
              >
                {Producto.sub_categoria.toLowerCase()}
              </Typography>
              <Link
                to={`/categoria/${Producto.detalle_sub_categoria}-${Producto.id_detalle_sub_categoria}`}
                style={{
                  textDecorationLine: "none",
                  color: "#333",
                  textTransform: "capitalize",
                }}
              >
                {Producto.detalle_sub_categoria.toLowerCase()}
              </Link>
            </Breadcrumbs>
            <Grid container spacing={2} style={{ margin: "0px" }}>
              <Grid item xs={12} sm={6}>
                <div>
                  <ImageGallery
                    items={Imagenes}
                    showFullscreenButton={false}
                    showNav={false}
                    showPlayButton={false}
                    thumbnailPosition="left"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography className={classes.textMarca}>
                      {Producto.marca}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      className={classes.textCodigo}
                    >{`Código: ${Producto.codigo}`}</Typography>
                  </Grid>
                </Grid>
                <Typography className={classes.textTitle}>
                  {Producto.producto}
                </Typography>
                <Typography
                  className={classes.textPrecio}
                >{`S/. ${Producto.precio_publico}`}</Typography>
                <Typography className={classes.textDescripcion}>
                  Descripción del producto
                </Typography>
                <Divider />
                <Typography className={classes.textDescripcion2}>
                  {Producto.descripcion}
                </Typography>
                <Typography className={classes.textList}>
                  {Producto.categoria}
                </Typography>
                <Typography className={classes.textList}>
                  {Producto.sub_categoria}
                </Typography>
                <Typography className={classes.textList}>
                  {Producto.detalle_sub_categoria}
                </Typography>
                <Typography
                  className={classes.textList}
                >{`MODELO: ${Producto.modelo}`}</Typography>
                <Grid container spacing={3} style={{ paddingTop: "25px" }}>
                  {
                    ItemsColores.length > 0 && ItemsTallas.length > 0 ? <>
                      <Grid item xs={6}>
                        <Typography variant="caption" color="primary">Color: </Typography>
                        <ul style={{listStyle: 'none', width: '100%',  textAlign: 'left', margin: '0px', padding: '0px'}}>
                          {
                            ItemsColores.map((item, key) => (
                              <li key={key} title={item.color} style={{width:'20px', margin: '5px', display: 'inline-flex', height:'20px', backgroundColor: item.hexadecimal, borderRadius: '20px', border: item.id === IdColor ? '2px solid #333' : '1px solid #CCC'}} onClick={ e => handleChangeColor (item.id)}></li>
                            ))
                          }
                        </ul>
                        </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth margin="dense" >
                          <InputLabel>Talla</InputLabel>
                          <Select label="Talla" onChange={ e => handleChangeTalla(e) } value={IdTalla} >
                            {ItemsTallas.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item.id}>
                                  {item.numero_talla}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </> : null 
                  }
                  <Grid item xs={12} sm={3}>
                    <TextField
                      type="number"
                      value={Cantidad}
                      margin="dense"
                      label="Cantidad"
                      id="cantidad"
                      name="cantidad"
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{
                        inputProps: { min: 1, max: Stock },
                      }}
                      onChange={handleChange}
                      onKeyPress={validarNumero}
                      onInput={validarNumero}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Button
                      style={{ marginTop: "10px" }}
                      fullWidth={true}
                      margin="dense"
                      variant="contained"
                      startIcon={<AddShoppingCartIcon />}
                      color="primary"
                      onClick={() => AgregarProducto(product_state)}
                    >
                      Agregar al carrito
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default DetalleProducto;
