import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Tooltip,
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  listar_tipos_documentos_identidad,
  consultar_tipos_documentos_identidad,
} from "../services/DocumentIdentity";

import { registrar_cliente, confirmar_cod_cliente } from "../services/Client";
import { ToastContext } from "../provider/Toast.provider";
import { useHistory, Link } from 'react-router-dom'
import { SpinnerCircleContext } from "../provider/SpinnerCircle.provider";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  Card: {
    minWidth: 275,
    boxShadow: "0 0 10px #999",
    borderRadius: "15px",
  },
  TestFieldCode: {
    width: "75px",
    margin: " 0 10px",
    textAlign: 'center',
    fontSize: '3rem'
  },
  showPassword: {
    fontSize: '1rem'
  },
  validaPassTrue: {
    color: '#04AA6D',
    fontSize: '.8rem'
  },
  validaPassFalse: {
    color: '#F00',
    fontSize: '.8rem'
  },
}));

function Register() {
  const classes = useStyles();
  const { setData } = useContext(ToastContext);
  const history = useHistory();
  //ALMACENA LISTADO DE TIPO DE DOC DE IDENTIFICACION
  const [lst_tipo_doc_ident, setlst_tipo_doc_ident] = useState([]);
  //CAMPOS PARA REGISTRO
  const [
    id_tipo_documento_identidad,
    setid_tipo_documento_identidad,
  ] = useState("");
  const [numero_documento_identidad, setnumero_documento_identidad] = useState("");
  const [nombres, setnombres] = useState("");
  const [ape_pat, setape_pat] = useState("");
  const [ape_mat, setape_mat] = useState("");
  const [correo, setcorreo] = useState("");
  const [celular, setcelular] = useState("");
  const [sexo, setsexo] = useState("");
  const [clave_cliente, setclave_cliente] = useState("");
  const [clave_cliente_confirmation, setclave_cliente_confirmation] = useState("");
  const [razon_social, setrazon_social] = useState("");
  const [recibir_correo, setrecibir_correo] = useState(false);
  const [Terminos, setTerminos] = useState(false);

  const [ShowPassword, setShowPassword] = useState(false)
  const [ValidaPass, setValidaPass] = useState(false)
  const [ValidaPass2, setValidaPass2] = useState(false)
  const [ValidaEmail, setValidaEmail] = useState(false)
  const [ItemsValidaPass, setItemsValidaPass] = useState({longitud: false, mayuscula: false, minuscula: false, caracter:false, numero:false})

  //ID DE CLIENTE AL REGISTRAR
  const [id_cliente, setid_cliente] = useState();
  //CÓDIGO DE CLIENTE
  const [cod_confirm_1, setcod_confirm_1] = useState();
  const [cod_confirm_2, setcod_confirm_2] = useState();
  const [cod_confirm_3, setcod_confirm_3] = useState();
  const [cod_confirm_4, setcod_confirm_4] = useState();
  const [cod_confirm_5, setcod_confirm_5] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [IsRazonSocial, setIsRazonSocial] = useState(false);
  const [TipoDocIden, setTipoDocIden] = useState(0);
  const [IsNumDocIde, setIsNumDocIde] = useState(false);
  const IdRuc = 2;
  const steps = getSteps();
  const { show, setshow } = useContext(SpinnerCircleContext);

  useEffect(() => {
    //CONSUME SERVICIO PARA TIPO DE DOCUMENTOS DE IDENTIDAD
    listar_tipos_documentos_identidad().then((result) => {
      setlst_tipo_doc_ident(result.data);
    });
  }, []);

  function handleChange(name, value, e) {
    switch (name) {
      case "nombres":
        setnombres(value.trim());
        break;
      case "ape_pat":
        setape_pat(value.trim());
        break;
      case "ape_mat":
        setape_mat(value.trim());
        break;
      case "correo":
        setcorreo(value.trim());
        ValidarEmail(value.trim())
        break;
      case "celular":
        setcelular(value.trim());
        break;
      case "razon_social":
        setrazon_social(value.trim());
        break;
      case "tipo_documento":
        setid_tipo_documento_identidad(value);
        consultar_tipos_documentos_identidad(value).then(
          (rTipoDocIdentidad) => {
            setTipoDocIden(rTipoDocIdentidad.data);
            document.getElementById("numero_documento").maxLength =
              rTipoDocIdentidad.data.longitud;
          }
        );
        if (IdRuc == value) {
          setIsRazonSocial(true);
        } else {
          setIsRazonSocial(false);
        }
        document.getElementById("numero_documento").value = "";
        break;
      case "numero_documento":
        if (TipoDocIden.numerico == 1) {
          if (TipoDocIden.longitud_exacta == 1) {
            if (isNaN(value) == false) {
              if (value.length == TipoDocIden.longitud) {
                setIsNumDocIde(false);
              } else {
                setIsNumDocIde(true);
              }
              setnumero_documento_identidad(value.trim());
            } else {
              setnumero_documento_identidad(value.substr(0, value.length - 1));
              document.getElementById("numero_documento").value = value.substr(
                0,
                value.length - 1
              );
            }
          }
        } else {
          setnumero_documento_identidad(value.trim());
        }
        break;
      case "sexo":
        setsexo(value.trim());
        break;
      case "contrasenia":
        setclave_cliente(value.trim());
        ValidarPassword(value.trim())
        break;
      case "contrasenia_confir":
        setclave_cliente_confirmation(value.trim());
        if (clave_cliente===value.trim()) {
          setValidaPass2(true)
        } else {
          setValidaPass2(false) 
        }
        break;
      case "recibir_correos":
        setrecibir_correo(value);
        break;
      case "terminos":
        setTerminos(value);
        break;
      case "cod_confirm_1":
        if (isNaN(value) == false && value.length == 1) {
          document.getElementById("cod_confirm_2").focus();
          document.getElementById("cod_confirm_2").select();
          setcod_confirm_1(value);
        } else {
          setcod_confirm_1("");
          document.getElementById("cod_confirm_1").value = "";
        }
        break;
      case "cod_confirm_2":
        if (isNaN(value) == false && value.length == 1) {
          document.getElementById("cod_confirm_3").focus();
          document.getElementById("cod_confirm_3").select();
          setcod_confirm_2(value);
        } else {
          setcod_confirm_2("");
          document.getElementById("cod_confirm_2").value = "";
          if (e.keyCode === 8) {
            document.getElementById("cod_confirm_1").focus()
          }
        }
        break;
      case "cod_confirm_3":
        if (isNaN(value) == false && value.length == 1) {
          document.getElementById("cod_confirm_4").focus();
          document.getElementById("cod_confirm_4").select();
          setcod_confirm_3(value);
        } else {
          setcod_confirm_3("");
          document.getElementById("cod_confirm_3").value = "";
          if (e.keyCode === 8) {
            document.getElementById("cod_confirm_2").focus()
          }
        }
        break;
      case "cod_confirm_4":
        if (isNaN(value) == false && value.length == 1) {
          document.getElementById("cod_confirm_5").focus();
          document.getElementById("cod_confirm_5").select();
          setcod_confirm_4(value);
        } else {
          setcod_confirm_4("");
          document.getElementById("cod_confirm_4").value = "";
          if (e.keyCode === 8) {
            document.getElementById("cod_confirm_3").focus()
          }
        }
        break;
      case "cod_confirm_5":
        if (isNaN(value) == false && value.length == 1) {
          document.getElementById("btnConfirmCodigo").focus();
          setcod_confirm_5(value);
        } else {
          setcod_confirm_5("");
          document.getElementById("cod_confirm_5").value = "";
          if (e.keyCode === 8) {
            document.getElementById("cod_confirm_4").focus()
          }
        }
        break;
      default:
        break;
    }
  }

  const ValidarPassword = (value) => {
    setValidaPass(false)
    let _longitud = false
    let _mayuscula = false
    let _minuscula = false
    let _caracter = false
    let _numero = false
    if (value.length > 7) {
      _longitud = true
    }
    for(var i = 0;i<value.length;i++) {
			if(value.charCodeAt(i) >= 65 && value.charCodeAt(i) <= 90) {
				_mayuscula = true;
			} else if(value.charCodeAt(i) >= 97 && value.charCodeAt(i) <= 122) {
				_minuscula = true;
			} else if(value.charCodeAt(i) >= 48 && value.charCodeAt(i) <= 57) {
				_numero = true;
			} else {
				_caracter = true;
			}
		}
    if (_longitud && _mayuscula && _minuscula && _caracter && _numero) {
      setValidaPass(true)
    }
    setItemsValidaPass({longitud: _longitud, mayuscula: _mayuscula, minuscula: _minuscula, caracter:_caracter, numero:_numero})
  }

  const ValidarEmail = (value) => {
    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value)) {
      setValidaEmail(true)
    } else {
      setValidaEmail(false)
    }
  }

  function OnClickRegister() {
    setshow(true)
    let model_register = {
      id_tipo_documento_identidad: id_tipo_documento_identidad,
      numero_documento_identidad: numero_documento_identidad,
      nombres: nombres,
      ape_pat: ape_pat,
      ape_mat: ape_mat,
      email: correo,
      celular: celular,
      sexo: sexo,
      password: clave_cliente,
      password_confirmation: clave_cliente_confirmation,
      razon_social: razon_social,
      recibir_correo: recibir_correo ? 1 : 0,
    };
    if (
      id_tipo_documento_identidad != "" && numero_documento_identidad.trim() != "" &&
      nombres.trim() != "" && ape_pat.trim() != "" && ape_mat.trim() != "" && (correo.trim() != "" && ValidaEmail) &&
      celular.trim() != "" && sexo.trim() != "" && clave_cliente.trim() != "" && clave_cliente_confirmation.trim() != "" && 
      recibir_correo && Terminos
     ) {
      if (clave_cliente.trim() != clave_cliente_confirmation.trim() || !ValidaPass || !ValidaPass2 ) {
        if (!ValidaPass) {
          setData({show:true, message:'La contraseña no cumple los requisitos de seguidad.', indicador:0})
        } else {
          setData({show:true, message:'Las contraseñas no coinciden.', indicador:0})
        }
      } else {
        registrar_cliente(model_register).then((result) => {
          if (result.exito == 1) {
            setid_cliente(result.data.id);
            handleNext(); //PARA PASAR EL SIGUIENTE PASO
          } else {
            if(typeof(result.mensaje) === 'object' && result.mensaje != 'null') {
              for (const mensaje in result.mensaje) {
                  setData({show:true, message:result.mensaje[mensaje], indicador:0})
              }
          } else {
              setData({show:true, message:result.mensaje, indicador:0})
          }
            // setData({show:true, message:'Complete los campos obligatorios.', indicador:0})
          }
          setshow(false)
        });
      }
    } else {
      setData({show:true, message:'Complete los campos obligatorios.', indicador:0})
    }
  }

  async function ConfirmarCodigo() {
    setshow(true)
    let model = {
      email: correo,
      code_confirmation:
        cod_confirm_1 +
        cod_confirm_2 +
        cod_confirm_3 +
        cod_confirm_4 +
        cod_confirm_5,
    };
    if (
      cod_confirm_1 != "" &&
      cod_confirm_2 != "" &&
      cod_confirm_3 != "" &&
      cod_confirm_4 != "" &&
      cod_confirm_5 != ""
    ) {
      await confirmar_cod_cliente(model, id_cliente).then((result) => {
        if (result.exito == 1) {
          setData({show:true, message:result.mensaje, indicador:1})
          history.push(`/`);
        } else {
          setData({show:true, message:result.mensaje, indicador:0})
        }
        setshow(false)
      });
    } else {
      setData({show:true, message:'Complete todos los campos correctamente.', indicador:0})
    }
  }

  function getSteps() {
    return ["Formulario de registro", "Confirmar código"];
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const contenerPass = (value) => {
    setShowPassword(value)
  }

  return (
    <div style={{ paddingTop: "80px"}}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              style={{ minHeight: "100vh" }}
            >
              <Grid item xs={12} sm={6}>
                <Box textAlign="center" m={2}>
                  <Typography className={classes.instructions}>
                    Registro completado con éxito.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={handleReset}
                    color="primary"
                  >
                    Iniciar sesión
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
              {activeStep == 0 ? (
                <div>
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    style={{ minHeight: "100vh" }}
                  >
                    <Grid item xs={12} sm={8}>
                      <Card>
                        <CardHeader title="Formulario de registro" style={{paddingBottom:'0px', paddingTop:'0px'}} />
                        <CardContent style={{paddingBottom:'0px', paddingTop:'0px'}} >
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              <Typography variant="caption">Complete sus datos personales y <span style={{color:'#F00'}}>*campos obligatorios</span></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                error={id_tipo_documento_identidad == ""}
                                required
                                variant="outlined"
                                fullWidth
                                margin="dense"
                              >
                                <InputLabel>Tipo de documento</InputLabel>
                                <Select
                                  label="Tipo de documento"
                                  onChange={(e) =>
                                    handleChange(
                                      "tipo_documento",
                                      e.target.value
                                    )
                                  }
                                >
                                  {lst_tipo_doc_ident.map((data, key) => {
                                    return (
                                      <MenuItem key={key} value={data.value}>
                                        {data.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                error={
                                  numero_documento_identidad.trim() == "" ||
                                  IsNumDocIde
                                }
                                id="numero_documento"
                                margin="dense"
                                label="Número de documento"
                                type="text"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                required
                                onChange={(e) =>
                                  handleChange(
                                    "numero_documento",
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            { IsRazonSocial ?
                            <Grid item xs={12} sm={12}>
                              <TextField
                                margin="dense"
                                label="Razón social"
                                type="text"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onChange={(e) =>
                                  handleChange("razon_social", e.target.value)
                                }
                              />
                            </Grid> : ''
                            }
                            <Grid item xs={12} sm={6}>
                              <TextField
                                error={nombres.trim() == ""}
                                margin="dense"
                                label="Nombres"
                                type="text"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                required
                                onChange={(e) =>
                                  handleChange("nombres", e.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                error={ape_pat.trim() == ""}
                                margin="dense"
                                label="Apellido Paterno"
                                type="text"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                required
                                onChange={(e) =>
                                  handleChange("ape_pat", e.target.value)
                                }
                              />    
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                error={ape_mat.trim() == ""}
                                margin="dense"
                                label="Apellido Materno"
                                type="text"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onChange={(e) =>
                                  handleChange("ape_mat", e.target.value)
                                }
                              /> 
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                error={correo.trim() == "" || !ValidaEmail}
                                margin="dense"
                                label="Correo Electrónico"
                                type="email"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                required
                                onChange={(e) =>
                                  handleChange("correo", e.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                error={clave_cliente.trim() == "" || !ValidaPass}
                                margin="dense"
                                label="Contraseña"
                                type="password"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                required
                                onChange={(e) =>
                                  handleChange("contrasenia", e.target.value)
                                }
                                onFocus={(e)=>contenerPass(true)}
                                onBlur={(e)=>contenerPass(false)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                error={clave_cliente_confirmation.trim() == "" || !ValidaPass2 || !ValidaPass}
                                margin="dense"
                                label="Confirmar contraseña"
                                type="password"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                required
                                onChange={(e) =>
                                  handleChange(
                                    "contrasenia_confir",
                                    e.target.value
                                  )
                                }
                              />  
                            </Grid>
                            { ShowPassword &&
                            <Grid item xs={12} sm ={12} className={classes.showPassword}>
                              <Typography variant="caption">La contraseña debe contener:</Typography>
                                <ul style={{margin: '0px'}}>
                                  <li className={ItemsValidaPass.longitud ? classes.validaPassTrue : classes.validaPassFalse} >8 caracteres como mínimo.</li>
                                  <li className={ItemsValidaPass.mayuscula ? classes.validaPassTrue : classes.validaPassFalse} >Al menos una mayúscula [A-Z].</li>
                                  <li className={ItemsValidaPass.minuscula ? classes.validaPassTrue : classes.validaPassFalse} >Al menos una minúscula [a-z].</li>
                                  <li className={ItemsValidaPass.numero ? classes.validaPassTrue : classes.validaPassFalse} >Al menos un número [0-9].</li>
                                  <li className={ItemsValidaPass.caracter ? classes.validaPassTrue : classes.validaPassFalse} >Al menos un caracter especial [@#$%&...].</li>
                                </ul>
                            </Grid>
                            }
                            <Grid item xs={12} sm={6}>
                              <TextField
                                error={
                                  celular.trim() == ""
                                }
                                margin="dense"
                                label="Celular"
                                type="number"
                                variant="outlined"
                                color="primary"
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 9);
                                }}
                                fullWidth
                                required
                                onChange={(e) =>
                                  handleChange("celular", e.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                error={sexo == ""}
                                required
                                variant="outlined"
                                fullWidth
                                margin="dense"
                              >
                                <InputLabel>Sexo</InputLabel>
                                <Select
                                  label="Tipo de documento"
                                  onChange={(e) =>
                                    handleChange("sexo", e.target.value)
                                  }
                                >
                                  <MenuItem value="M">Masculino</MenuItem>
                                  <MenuItem value="F">Femenino</MenuItem>
                                </Select>
                              </FormControl> 
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <FormControlLabel
                                control={<Checkbox checked={recibir_correo}
                                id="recibir_correos"
                                name="recibir_correos"
                                color="primary"
                                onChange={(e) =>
                                  handleChange(
                                    "recibir_correos",
                                    e.target.checked
                                  )
                                } />}
                                label={<> Autorizo recibir comunicaciones promocionales y autorizo el uso de mi información para <Link target="blank" to={`/terminos_condiciones`} style={{color:'#333',}}>fines adicionales.</Link></>}
                                labelPlacement="end"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                            <FormControlLabel
                                control={<Checkbox checked={Terminos}
                                id="terminos"
                                name="terminos"
                                color="primary"
                                onChange={(e) =>
                                  handleChange(
                                    "terminos",
                                    e.target.checked
                                  )
                                } />}
                                label={<> Declaro que he leído y acepto la nueva <Link target="blank" to={`/terminos_condiciones`} style={{color:'#333',}}>Política de Privacidad</Link> y los <Link target="blank" to={`/terminos_condiciones`} style={{color:'#333',}}>Términos y condiciones</Link> de E-commerce. </>}
                                labelPlacement="end"
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                        <Box textAlign="center" m={1}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => OnClickRegister()}
                          >
                            Continuar
                          </Button>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
              ) : null}
              {activeStep == 1 ? (
                <div>
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    style={{ minHeight: "100vh" }}
                  >
                    <Grid item xs={12} sm={8}>
                      <Card>
                        <CardHeader title="Confirmación de código" style={{paddingBottom:'0px', paddingTop:'0px'}}  />
                        <CardContent>
                          <Typography>
                            Revise el código que se le envió en su correo y
                            escribalo en la sección de abajo.
                          </Typography>
                          <Box textAlign="center" m={2}>
                            <TextField
                              id="cod_confirm_1"
                              label=""
                              type="text"
                              variant="outlined"
                              color="primary"
                              className={classes.TestFieldCode}
                              onKeyUp={(e) =>
                                handleChange("cod_confirm_1", e.target.value, e)
                              }
                            />
                            <TextField
                              id="cod_confirm_2"
                              label=""
                              type="text"
                              variant="outlined"
                              color="primary"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 1);
                              }}
                              className={classes.TestFieldCode}
                              onKeyUp={(e) =>
                                handleChange("cod_confirm_2", e.target.value, e)
                              }
                            />
                            <TextField
                              id="cod_confirm_3"
                              label=""
                              type="text"
                              variant="outlined"
                              color="primary"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 1);
                              }}
                              className={classes.TestFieldCode}
                              onKeyUp={(e) =>
                                handleChange("cod_confirm_3", e.target.value, e)
                              }
                            />
                            <TextField
                              id="cod_confirm_4"
                              label=""
                              type="text"
                              variant="outlined"
                              color="primary"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 1);
                              }}
                              className={classes.TestFieldCode}
                              onKeyUp={(e) =>
                                handleChange("cod_confirm_4", e.target.value, e)
                              }
                            />
                            <TextField
                              id="cod_confirm_5"
                              label=""
                              type="text"
                              variant="outlined"
                              color="primary"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 1);
                              }}
                              className={classes.TestFieldCode}
                              onKeyUp={(e) =>
                                handleChange("cod_confirm_5", e.target.value, e)
                              }
                            />
                          </Box>
                        </CardContent>
                        <Box textAlign="center" m={2}>
                          <Button
                            variant="contained"
                            id="btnConfirmCodigo"
                            color="primary"
                            onClick={() => ConfirmarCodigo()}
                          >
                            {activeStep === steps.length - 1
                              ? "Finalizar"
                              : "Continuar"}
                          </Button>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
              ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default Register;
