export function mostrar_tienda_principal (){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'tienda/tienda_principal';
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}