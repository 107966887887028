import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({

    footer: {
        backgroundColor:'#393939',
        textAlign:'center',
        paddingTop:'25px',
        paddingBottom:'25px',
        // bottom:'0',
        // position:'absolute',
        // width:'100%',
        // height:'auto',
        display:'flex',
        flexDirection:'column',
        // minHeight:'100vh'
    },
    letter: {
        color:'#fff'
    }
}));

const Footer = () => {
    
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            
            <Typography
            className={classes.letter}
            variant="subtitle1">
                © 2021 MadamMar - Todos los derechos reservados.
            </Typography>
        </footer>
    )
}

export default Footer;
