export function listarEstadoWeb () {

    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let baseUrl = base + "empresa/listar_estado_web";
    
    return new Promise ((resolve, reject) => {
        fetch(baseUrl, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson);
        })
        .catch((error)=>{
            reject(error);
        });
    });
    }