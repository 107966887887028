import React, { useEffect, useState } from "react";
import { datos_login } from "../../services/Client";
import { consultar_tipos_documentos_identidad } from '../../services/DocumentIdentity'
import { Card, Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    width: "100%",
    height: "100%",
    boxShadow: "0 0 10px #999",
    borderRadius: "15px",
    padding: "10px",
  },
}));
const DatosPersonales = () => {
  const classes = useStyles;
  const datos_personales = {
    nombres: "",
    ape_pat: "",
    ape_mat: "",
    numero_documento_identidad: "",
    tipo_documento_identidad: "",
  };
  const [state_datos_personales, setstate_datos_personales] = useState(
    datos_personales
  );
  useEffect(() => {
    datos_login(
      JSON.parse(localStorage.getItem("_token_webEcommerce")).token
    ).then((result) => {
      consultar_tipos_documentos_identidad(result.data.cliente.id_tipo_documento_identidad).then((rDocumento) => {
        if (rDocumento.exito == 1) {
          setstate_datos_personales({
            nombres: result.data.cliente.nombres,
            ape_pat: result.data.cliente.ape_pat,
            ape_mat: result.data.cliente.ape_mat,
            numero_documento_identidad: result.data.cliente.numero_documento_identidad,
            tipo_documento_identidad: rDocumento.data.nombre,
          });
        }
      })
    });
  }, []);

  return (
    <Card className={classes.root} style={{ padding: "10px" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">Datos Personales</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="caption" style={{ color: "#666" }}>
            Nombres:
          </Typography>
          <Typography variant="subtitle2" style={{ color: "#333" }}>
            {state_datos_personales.nombres} {state_datos_personales.ape_pat}{" "}
            {state_datos_personales.ape_mat}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="caption" style={{ color: "#666" }}>
          {state_datos_personales.tipo_documento_identidad}:
          </Typography>
          <Typography variant="subtitle2">
            {state_datos_personales.numero_documento_identidad}
          </Typography>
          <Divider />
        </Grid>
      </Grid>
    </Card>
  );
};

export default DatosPersonales;
