
import React, {useEffect, useState} from 'react'
import { Paper, Typography } from '@material-ui/core'
import useStyles from './styles';
import Carousel from 'react-material-ui-carousel';
import { listado_slider } from '../../services/Slider'

const CarouselLayout =()=> {
    const classes = useStyles();
    const BASE_URL= process.env.REACT_APP_BASE_URL_DEV
    const [Items, setItems] = useState([])

    useEffect(() => {
        listado_slider().then((result) => {
            if (result.exito==1) {
                setItems(result.data)
            }
        })
    }, [])

    const Item = (props) => {
        return (<Paper>
                    <img src={`${BASE_URL}storage/images/slider/${props.item.url_imagen}`} alt={props.item.titulo}/>
                    <Typography size="18" className={classes.titleSlider}>
                        {props.item.titulo}
                    </Typography>
                    <Typography size="18" className={classes.descripcionSlider}>
                        {props.item.descripcion}
                    </Typography>
                </Paper>)
    }
    return (
        <div style={{width:'100%', marginTop:'80px', textAlign:'center'}}>
            <Carousel stopAutoPlayOnHover={false} style={{width:'100%', margin:'auto'}}>
                {
                    Items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>
        </div>
    )
}

export default CarouselLayout
