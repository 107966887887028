import {makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme)=> ({
    toolbar: theme.mixins.toolbar,
    root: {
      flexGrow: 1,
    },
    content: {
   
        padding: theme.spacing(2),
      },
      paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        marginTop:'5px',
        boxShadow:'0 0 10px #999',
        // maxWidth: 500,
      },
      image: {
        width: 128,
        height: 128,
      },
      img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
      },
}));