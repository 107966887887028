import React, { useEffect, useState } from "react";
import { Button, Card, FormControlLabel, Grid, Radio, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Direcciones from "../Direcciones/Direcciones";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    width: "100%",
    height: "100%",
    boxShadow: "0 0 10px #999",
    borderRadius: "15px",
    padding: "10px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: "1200px",
  },
}));

const DireccionesCompra = ({ setIndicadorBtnSiguiente, setTipoEnvioDir, TipoEnvio, setIdDireccionDir, IdDireccion }) => {
  const classes = useStyles;
  const [selectedValueRadioBtn, setSelectedValueRadioBtn] = useState(IdDireccion);
  const [
    SelectedValueRadioBtnCompra,
    setSelectedValueRadioBtnCompra,
  ] = useState(TipoEnvio);

  useEffect(() => {
    setSelectedValueRadioBtnCompra(TipoEnvio)
    setSelectedValueRadioBtn(IdDireccion)
  }, [])

  useEffect(() => {
    if (SelectedValueRadioBtnCompra === "T") {
      setIndicadorBtnSiguiente(true);
    } else if (SelectedValueRadioBtnCompra === "E") {
      setIndicadorBtnSiguiente(false);
      if (selectedValueRadioBtn !== 0) {
        setIndicadorBtnSiguiente(true);
      } else {
        setIndicadorBtnSiguiente(false);
      }
    }
  }, [SelectedValueRadioBtnCompra]);

  useEffect(() => {
    if (SelectedValueRadioBtnCompra === "E" && selectedValueRadioBtn !== 0) {
      setIndicadorBtnSiguiente(true);
    } else if (SelectedValueRadioBtnCompra === "E" && selectedValueRadioBtn === 0) {
      setIndicadorBtnSiguiente(false);
    }
  }, [selectedValueRadioBtn]);

  useEffect(() => {
    setIdDireccionDir(selectedValueRadioBtn)
  }, [selectedValueRadioBtn])

  const handleChangeRadioBtn = (e) => {
    setSelectedValueRadioBtnCompra(e.target.value);
    setTipoEnvioDir(e.target.value)
    if (e.target.value == 'T') {
      setIdDireccionDir(0)
      setSelectedValueRadioBtn(0)
      setIndicadorBtnSiguiente(true)
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Card className={classes.root} style={{ paddingLeft: "10px" }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={<Radio color="default" name="radio-buton-direcciones-compra" value="T" onChange={handleChangeRadioBtn} checked={SelectedValueRadioBtnCompra === "T"} />} 
                label="Recojo en tienda" />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Card className={classes.root} style={{ paddingLeft: "10px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
            <FormControlLabel
                control={<Radio color="default" name="radio-buton-direcciones-compra" value="E" onChange={handleChangeRadioBtn} checked={SelectedValueRadioBtnCompra === "E"} />} 
                label="Envío a domicilio" />
            </Grid>
            <Grid item xs={12} sm={11}>
              {SelectedValueRadioBtnCompra === "E" ? (
                <Direcciones
                  selectedValueRadioBtn={selectedValueRadioBtn}
                  setSelectedValueRadioBtn={setSelectedValueRadioBtn}
                  selectDireccion={true}
                />
              ) : null}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DireccionesCompra;
