import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  ButtonBase,
  Paper,
  TextField,
  IconButton,
  Button,
  Box,
  Divider,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import useStyles from "./styles";

import cart_image from "../../assets/carts.png";
import { NavbarContext } from "../../provider/Navbar.provider";
import SignIn from "../SignIn/SignIn";
import { isAuthenticated } from "../../services/auth/authentication";
import { ToastContext } from "../../provider/Toast.provider";
import { useHistory } from "react-router-dom";
import ResumenCompra from "./ResumenCompra";
import { Table } from "@material-ui/core";

function CarritoCompra() {
  const history = useHistory();
  // Context Navbar
  const { settotal } = useContext(NavbarContext);
  // Context Toast
  const { setData } = useContext(ToastContext);

  const [OpenDialogSignin, setOpenDialogSignin] = useState(false);
  const classes = useStyles();
  const base = process.env.REACT_APP_BASE_URL_DEV;
  const [productos_carrito, setproductos_carrito] = useState([]);
  const [total_price, settotal_price] = useState(0);
  const [MontoEnvio, setMontoEnvio] = useState(0);
  const [btncomprarstate, setbtncomprarstate] = useState(false);
  const [ProductosList, setProductosList] = useState(JSON.parse(localStorage.getItem("products")))

  const validarNumero = (e) => {
    var ch = String.fromCharCode(e.which);
    if (!/[0-9]/.test(ch)) {
      e.preventDefault();
    }
  }

  //Validar cantidad
  function incrementarStock(e, product, idProductoTallaColor, idAlmacen) {
    let products = JSON.parse(localStorage.getItem("products"));
   
    for (let i = 0; i < products.length; i++) {
      if (products[i].productId.id_almacen_stock === product.id_almacen_stock) {
        if (idProductoTallaColor === 0 && idAlmacen === 0) {
          if (parseInt(e.target.value) > 0 && parseInt(e.target.value) <= product.stock) {
            products[i].productId.cantidad = parseInt(e.target.value);
          } else {
            products[i].productId.cantidad = 1;
            setData({show: true, message: `La cantidad ingresada no debe ser mayor al stock actual: ${product.stock}`, indicador: 0})
          }
        } else {
          for (let j = 0; j < products[i].productId.items.length; j++) {
              const element = products[i].productId.items[j];
              if (element.id_producto_talla_color == idProductoTallaColor && element.id_almacen == idAlmacen) {
                if (parseInt(e.target.value) > 0 && parseInt(e.target.value) <= products[i].productId.items[j].stock) {
                  products[i].productId.cantidad = products[i].productId.cantidad - products[i].productId.items[j].cantidad + parseInt(e.target.value);
                  products[i].productId.items[j].cantidad = parseInt(e.target.value)
                } else {
                  products[i].productId.items[j].cantidad = parseInt(1)
                  setData({show: true, message: `La cantidad ingresada no debe ser mayor al stock actual: ${products[i].productId.items[j].stock}`, indicador: 0})
                }
              }
          }
        }
        setProductosList(products)
        localStorage.setItem("products", JSON.stringify(products));
        CalculateTotalPrice();
      }
    }
  }
  // Calcular precio total de todos los productos
  function CalculateTotalPrice() {
    let products = JSON.parse(localStorage.getItem("products"));
    let pricexproduct = 0;
    if (products.length >= 1) {
      for (let i = 0; i < products.length; i++) {
        pricexproduct =
          products[i].productId.cantidad *
            products[i].productId.precio_publico +
          pricexproduct;
        if (products.length - 1 === i) {
          settotal_price(pricexproduct);
        }
      }
    } else {
      localStorage.removeItem("products");
      settotal_price(0);
      setbtncomprarstate(false);
    }
  }

  // Eliminar producto
  function DeleteProduct(kei_product) {
    let products = JSON.parse(localStorage.getItem("products"));
    products.splice(kei_product, 1);
    localStorage.setItem("products", JSON.stringify(products));
    setProductosList(products)
    setproductos_carrito(JSON.parse(localStorage.getItem("products")));
    CalculateTotalPrice();
    settotal(products.length);
    setData({show:true,message:'Producto eliminado.',indicador:1});
  }

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("products"))) {
      setproductos_carrito(JSON.parse(localStorage.getItem("products")));
      CalculateTotalPrice();
      setbtncomprarstate(true);
    }
  }, [ProductosList]);

  return ( <>
    <div className={classes.toolbar} />
    <div>
      <SignIn open={OpenDialogSignin} setopen={setOpenDialogSignin} />
      <div className={classes.root}>
        <Grid container spacing={3} style={{ margin: "0px", width: "auto" }}>
          <Grid item xs={12} sm={9}>
            {productos_carrito.length >= 1 ? (
              productos_carrito.map((i, key) => (
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        onClick={() => DeleteProduct(key)}
                        component="span"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <ButtonBase className={classes.image}>
                        <img
                          className={classes.img}
                          alt="complex"
                          src={
                            i.productId.url !== null ?
                            base + `storage/images/products/thumbnails/${i.productId.url}`
                            :
                            base + `storage/images/products/default-image.jpg`
                          }
                        />
                      </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Typography gutterBottom variant="subtitle1">
                            {i.productId.producto}
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {i.productId.descripcion}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Código: {i.productId.codigo}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          {
                            i.productId.items.length > 0 &&
                            <TableContainer>
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align='center' style={{backgroundColor:'#CCC', padding:'5px', color:'#666'}}>Talla</TableCell>
                                    <TableCell align='center' style={{backgroundColor:'#CCC', padding:'5px', color:'#666'}}>Color</TableCell>
                                    <TableCell align='center' style={{backgroundColor:'#CCC', padding:'5px', color:'#666'}}>Cantidad</TableCell>
                                  </TableRow>
                                </TableHead>
                              {
                              i.productId.items.map((item, key) => (
                                <TableRow key={key}>
                                  <TableCell style={{margin:'0px', padding:'0px', textAlign: 'center'}}>{item.numero_talla}</TableCell>
                                  <TableCell style={{margin:'0px', padding:'0px', textAlign: 'center'}}><div title={item.color} style={{width:'20px', margin: 'auto', height:'20px', backgroundColor: item.hexadecimal, borderRadius: '20px', border: '1px solid #999'}}></div></TableCell>
                                  <TableCell style={{margin:'0px', padding:'0px', textAlign: 'center'}}><TextField margin="dense" color="primary" label="" variant="outlined" value={item.cantidad} type="number" style={{width: '30%'}}
                              InputProps={{ inputProps: { min: 1, max: item.stock },}} onChange={(e) => incrementarStock(e, i.productId, item.id_producto_talla_color, item.id_almacen)}  onKeyPress={validarNumero} onInput={validarNumero}  /></TableCell>
                                </TableRow>
                              ))
                              }
                              </Table>
                            </TableContainer>
                          }
                        </Grid>
                      </Grid>
                      <Grid>
                        <Grid item xs>
                          <Typography
                            variant="subtitle1"
                            style={{ color: "#336D40", fontWeight: "bold" }}
                          >
                            S/. {i.productId.precio_publico} UN.
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={5}>
                            <Grid item xs={12}>
                              {
                                i.productId.items.length > 0 ?
                                <TextField margin="dense" color="primary" label="Cantidad" variant="outlined" value={i.productId.cantidad} type="number" disabled fullWidth />
                                :
                                <TextField margin="dense" color="primary" label="Cantidad" variant="outlined" value={i.productId.cantidad} type="number" fullWidth
                                InputProps={{ inputProps: { min: 1, max: i.productId.stock },}} onChange={ e => incrementarStock(e, i.productId, 0, 0)} onKeyPress={validarNumero} onInput={validarNumero} />
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              ))
            ) : (
              <Paper className={classes.paper}>
                <Box textAlign="center">
                  <img src={cart_image} alt="Commerce.js" height="150px" />
                  <Typography variant="subtitle1">
                    No tiene productos en su carrito de compras.
                  </Typography>
                </Box>
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            <ResumenCompra montoCompra={total_price} montoEnvio={MontoEnvio} btnEstado={btncomprarstate} OpenSignin={setOpenDialogSignin} />
          </Grid>
        </Grid>
      </div>
    </div>
  </> );
}

export default CarritoCompra;
