import React, { createContext, useEffect, useState } from "react";

const SpinnerCircleProvider = (props) => {
    const [show, setshow] = useState(false);

    return(
        <SpinnerCircleContext.Provider value={{show,setshow}}>
            {props.children}
        </SpinnerCircleContext.Provider>
    );
}

export default SpinnerCircleProvider;
export const SpinnerCircleContext = createContext();