import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  typography: {
    fontFamily: 'Roboto, Arial',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  gridContainer:{
    paddingLeft:'20px',
    paddingRight: '20px'
  },
  gridFilter : {
    background:'#DADADA',
     padding:'25px 10px',
     marginTop:'25px',
     borderRadius: '15px'
  },
  textMarca: {
    fontSize: '.8rem',
    color: '#666',
    fontWeight: 'bold'
  },
  textCodigo: {
    fontSize: '.8rem',
    color: '#999',
    textAlign: 'right',
  },
  textTitle: {
    fontSize: '1.8rem',
    color: '#333',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  textPrecio: {
    fontSize: '1.2rem',
    color: '#000',
    paddingBottom: '10px',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  textDescripcion: {
    fontSize: '.8rem',
    color: '#333',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  textDescripcion2: {
    fontSize: '.8rem',
    color: '#666',
    textAlign: 'left',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  textList: {
    fontSize: '.8rem',
    color: '#666',
    textAlign: 'left',
    paddingTop: '5px',
  },
}));