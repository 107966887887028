import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NavbarClient from "../../components/NavbarClient/NavbarClient";
import AddSharpIcon from '@material-ui/icons/AddSharp';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DireccionComponent from "./Direccion/Direccion";

import {
  listar_ubigeos,
  guardar_ubigeo_cliente,
  eliminar_ubigeo_cliente,
} from "../../services/UbigeoCliente";

import { isAuthenticated } from "../../services/auth/authentication";
import NewDireccion from "./Direccion/NewDireccion";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    maxWidth: "200px",
  },
  root: {
    flexGrow: 1,
    ".MuiTextField-root": {
      fontSize: "0.6rem",
    },
  },
}));

const Direcciones = ({ selectedValueRadioBtn, setSelectedValueRadioBtn, selectDireccion=false }) => {
  const classes = useStyles;

  const [DatosDireccion, setDatosDireccion] = useState({id_cliente:'', ubigeo: '', direccion:'', referencia:'', predeterminado:''})  
  const [IdUbigeoCliente, setIdUbigeoCliente] = useState("");
  const [ItemsUbigeoCliente, setItemsUbigeoCliente] = useState([]);
  const [Predeterminado, setPredeterminado] = useState(0)

  const [dialogDelete, setDialogDelete] = useState(false);

  const _cliente = JSON.parse(localStorage.getItem("_token_webEcommerce")).cliente;
  const _token = JSON.parse(localStorage.getItem("_token_webEcommerce")).token;

  useEffect(() => {
    if (isAuthenticated) {
      listarUbigeoCliente();
    }
  }, []);

  useEffect(() => {
    listarUbigeoCliente();
  }, [Predeterminado]);

  const handleChangeRadioBtn = (event) => {
    setSelectedValueRadioBtn(parseInt(event.target.value));
  };
  const listarUbigeoCliente = () => {
    listar_ubigeos(_cliente.id, _token).then((result) => {
      if (result.exito === 1) {
        setItemsUbigeoCliente(result.data);
      } else {
        console.log(result.mensaje);
      }
    });
  };

  const saveDireccion = () => {
    let _token = JSON.parse(localStorage.getItem("_token_webEcommerce")).token;
    let request = DatosDireccion;
    guardar_ubigeo_cliente(request, _token).then((result) => {
      if (result.exito === 1) {
        console.log(result.mensaje);
        setOpenNew(false)
        listarUbigeoCliente();
      } else {
        console.log(result.mensaje);
      }
    });
  };

  const openDialog = (id) => {
    setIdUbigeoCliente(id);
    dialogDelete ? setDialogDelete(false) : setDialogDelete(true);
  };

  const [OpenNew, setOpenNew] = useState(false)
  const agregarDireccion = () => {
    setOpenNew(true)
  }

  const hadleCloseNew = () => {
    setOpenNew(false);
  }

  const deleteDireccion = () => {
    eliminar_ubigeo_cliente(IdUbigeoCliente, _token).then((result) => {
      if (result.exito == 1) {
        console.log(result.mensaje);
        setDialogDelete(false);
        listarUbigeoCliente();
      } else {
        console.log(result.mensaje);
      }
    });
  };

  return (
    <>
      <div className={classes.content} style={{ margin: "15px" }}>
        <div className={classes.toolbar} />

        <Grid container spacing={2}>
          { !selectDireccion ?
          <Grid item xs={12}>
            <Typography variant="h5">Mis direcciones</Typography>
            <Divider />
          </Grid>
          : ''}
          
          {ItemsUbigeoCliente.map((item, key) => (
            <Grid item xs={12} sm={6} style={{ margin: "0px", width: "100%" }}>
              <DireccionComponent
                ItemsUbigeoCliente={item}
                index={key}
                openDialog={openDialog}
                handleChangeRadioBtn={handleChangeRadioBtn}
                selectedValueRadioBtn={selectedValueRadioBtn}
                selectDireccion={selectDireccion}
                setPredeterminado={setPredeterminado}
              />
            </Grid>
          ))}
          { ItemsUbigeoCliente.length < 3 &&
          <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={agregarDireccion} fullWidth startIcon={<AddSharpIcon />}>Agregar otra dirección</Button>
          </Grid>
          }
          <Dialog
            open={dialogDelete}
            onClose={openDialog}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <DialogTitle id="form-dialog-title">Confirmación</DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
              <Typography variant="caption">
                ¿Seguro que desea elminar esta dirección?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={openDialog} color="primary">
                No, cancelar{" "}
              </Button>
              <Button
                color="primary"
                onClick={deleteDireccion}
                variant="contained"
              >
                Si, eliminar{" "}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={OpenNew}
            onClose={OpenNew}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <DialogTitle id="form-dialog-title">Nueva dirección</DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
              <NewDireccion ItemsUbigeoCliente={ItemsUbigeoCliente} setDatosDireccion={setDatosDireccion} />
            </DialogContent>
            <DialogActions>
              <Button onClick={hadleCloseNew} color="primary">
                No, cancelar
              </Button>
              <Button
                color="primary"
                onClick={saveDireccion}
                variant="contained"
              >
                Si, guardar
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </div>
    </>
  );
};

export default Direcciones;
