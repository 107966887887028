
export function listar_tipos_documentos_identidad(){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'tipodocumentoidentidad/lista_activos';
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}


export function consultar_tipos_documentos_identidad(id){
    const base = process.env.REACT_APP_BASE_URL_DEV_API;
    let ws = base + 'tipodocumentoidentidad/'+id;
    return new Promise ((resolve, reject)=>{
        fetch(ws,{
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response)=>response.json())
        .then((responseJson)=>{
            resolve(responseJson)
        })
        .catch((error)=>{
            reject(error);
        });
    });
}
