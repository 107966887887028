import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  gridContainer:{
    paddingLeft:'20px',
    paddingRight: '20px'
  },
  gridFilter : {
    background:'#DADADA',
     padding:'25px 10px',
     marginTop:'0px',
     borderRadius: '15px'
  }
}));